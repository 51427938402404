import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Building, ChevronDown } from 'lucide-react';
import { API_URL } from '../../config';

export default function BatchDistributionForm() {
  const { batchId } = useParams();
  const [branches, setBranches] = useState([]);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBranchId, setSelectedBranchId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all forms for this batch
        const formsResponse = await axios.get(`${API_URL}/api/distributions/batch-forms/${batchId}`);
        setForms(formsResponse.data);

        // Get unique branch IDs from forms
        const uniqueBranchIds = [...new Set(formsResponse.data.map(form => form.branch))];
        
        // Fetch branch details
        const branchResponse = await axios.get(`${API_URL}/api/distributions/public/branches`);
        const filteredBranches = branchResponse.data
          .filter(branch => uniqueBranchIds.includes(branch._id))
          .sort((a, b) => a.name.localeCompare(b.name)); // Sort branches alphabetically
        
        setBranches(filteredBranches);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('שגיאה בטעינת הנתונים');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [batchId]);

  const handleBranchSelect = (event) => {
    const branchId = event.target.value;
    if (!branchId) return;

    // Find all forms for this branch
    const branchForms = forms.filter(form => form.branch === branchId);
    
    // If only one form, go directly to it
    if (branchForms.length === 1) {
      navigate(`/distribution-form/${branchForms[0].accessToken}`);
    } else {
      // If multiple forms, go to the branch forms view and pass the batch ID
      navigate(`/distribution-form/branch/${branchId}`, { 
        state: { fromBatchId: batchId } 
      });
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (branches.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center p-8">
          <p className="text-gray-500">אין טפסים זמינים לקבוצה זו</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50" dir="rtl">
      <div className="max-w-4xl mx-auto p-2 sm:p-6">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-4">
            <h1 className="text-lg sm:text-2xl font-bold text-center">טפסי חלוקות סחורה</h1>
            <p className="text-sm text-blue-100 text-center mt-1">נא לבחור את הסניף שלך מהרשימה</p>
          </div>
        </div>

        {/* Branch Selection */}
        <div className="mt-6 bg-white rounded-lg shadow-sm p-6">
          <div className="relative">
            <label htmlFor="branch-select" className="block text-sm font-medium text-gray-700 mb-2">
              בחר סניף:
            </label>
            <div className="relative">
              <select
                id="branch-select"
                value={selectedBranchId}
                onChange={handleBranchSelect}
                className="block w-full pl-3 pr-10 py-3 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-lg appearance-none"
              >
                <option value="">-- בחר סניף --</option>
                {branches.map((branch) => {
                  const formCount = forms.filter(form => form.branch === branch._id).length;
                  return (
                    <option key={branch._id} value={branch._id}>
                      {branch.name} {branch.branch_id ? `(${branch.branch_id})` : ''} - {formCount} {formCount === 1 ? 'טופס' : 'טפסים'}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                <ChevronDown className="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>

        {/* Instructions */}
        <div className="mt-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
          <h3 className="font-medium text-blue-800 mb-2 flex items-center">
            <Building className="h-5 w-5 ml-2" />
            הנחיות:
          </h3>
          <ul className="space-y-2 text-sm text-blue-700">
            <li>• בחר את הסניף שלך מהרשימה הנפתחת</li>
            <li>• מספר הטפסים הזמינים לכל סניף מוצג ליד שם הסניף</li>
            <li>• לאחר בחירת הסניף תועבר אוטומטית לטופס המתאים</li>
            <li>• אם יש מספר טפסים לסניף שלך, תוכל לבחור את הטופס הרצוי</li>
          </ul>
        </div>
      </div>
    </div>
  );
} 