import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { FileUp, FileCheck, ChevronDown, ChevronUp, Info, HelpCircle, Check } from 'lucide-react';
import { API_URL } from '../../config';

export default function DistributionUpload() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [processingFile, setProcessingFile] = useState(false);
  const [showColumnSelection, setShowColumnSelection] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isNewFormat, setIsNewFormat] = useState(false);
  const [sheetsInfo, setSheetsInfo] = useState([]);
  
  // Sheet selection
  const [selectedSheets, setSelectedSheets] = useState([]);
  const [selectAllSheets, setSelectAllSheets] = useState(false);

  // Old format column selections
  const [branchColumn, setBranchColumn] = useState('');
  const [itemColumn, setItemColumn] = useState('');
  const [barcodeColumn, setBarcodeColumn] = useState('');
  const [packageFactorColumn, setPackageFactorColumn] = useState('');
  
  // New format column selections
  const [branchIdColumn, setBranchIdColumn] = useState('');
  const [branchNameColumn, setBranchNameColumn] = useState('');
  const [newPackageFactorColumn, setNewPackageFactorColumn] = useState('');
  
  // Common
  const [timeLimit, setTimeLimit] = useState('24'); // Default 24 hours
  const [batchName, setBatchName] = useState('');

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      // Reset state when new file is selected
      setSheetsInfo([]);
      setSelectedSheets([]);
      setShowColumnSelection(false);
      setIsNewFormat(false);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error('נא לבחור קובץ');
      return;
    }

    // Validate file type
    if (!file.type.includes('spreadsheet') && !file.type.includes('excel')) {
      toast.error('נא להעלות קובץ אקסל בלבד');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/api/distributions/upload`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const { sheetsInfo, isNewFormat, error, shouldRetry } = response.data;
      
      // Handle storage error but successful file processing
      if (error === 'Storage error' && shouldRetry) {
        toast.warning('הקובץ עובד בהצלחה אך נכשל בשמירה. נא לנסות שוב.');
        return;
      }

      setSheetsInfo(sheetsInfo);
      setIsNewFormat(isNewFormat);
      setShowColumnSelection(true);
      
      // Default select all sheets
      setSelectedSheets(sheetsInfo.map(sheet => sheet.name));
      setSelectAllSheets(true);
      
      toast.success('הקובץ הועלה בהצלחה, נא להמשיך בהגדרות');
      
      if (isNewFormat) {
        autodetectNewFormatColumns(sheetsInfo);
      } else {
        autodetectOldFormatColumns(sheetsInfo);
      }
      
    } catch (error) {
      console.error('Upload error:', error);
      const errorMessage = error.response?.data?.message || 'שגיאה בהעלאת הקובץ';
      const errorDetails = error.response?.data?.details;
      
      if (errorDetails) {
        toast.error(`${errorMessage}\n${errorDetails}`);
      } else {
        toast.error(errorMessage);
      }
      
      // Reset file input on error
      if (document.getElementById('upload-form')) {
        document.getElementById('upload-form').reset();
      }
      setFile(null);
    } finally {
      setLoading(false);
    }
  };
  
  const autodetectNewFormatColumns = (sheetsInfo) => {
    // Find a sheet with table headers
    const sheetWithTable = sheetsInfo.find(sheet => 
      sheet.isNewFormat && 
      sheet.tableHeaders && 
      sheet.tableHeaders.length > 0
    );
    
    if (!sheetWithTable) return;
    
    const { tableHeaders } = sheetWithTable;
    
    // Common column names for branch ID, branch name, and package factor
    const branchIdPatterns = ['מספר סניף', 'מס סניף', 'מס\'', 'מספר'];
    const branchNamePatterns = ['סניף', 'שם סניף'];
    const packageFactorPatterns = ['כמות גורמי אירוז', 'כמות גורם אירוז', 'גורמי אירוז'];
    
    // Try to find matching columns
    for (const header of tableHeaders) {
      // Check for branch ID
      if (!branchIdColumn && branchIdPatterns.some(pattern => 
        header === pattern || (typeof header === 'string' && header.includes(pattern))
      )) {
        setBranchIdColumn(header);
      }
      
      // Check for branch name
      if (!branchNameColumn && branchNamePatterns.some(pattern => 
        header === pattern || (typeof header === 'string' && header.includes(pattern))
      )) {
        setBranchNameColumn(header);
      }
      
      // Check for package factor
      if (!newPackageFactorColumn && packageFactorPatterns.some(pattern => 
        header === pattern || (typeof header === 'string' && header.includes(pattern))
      )) {
        setNewPackageFactorColumn(header);
      }
    }
  };
  
  const autodetectOldFormatColumns = (sheetsInfo) => {
    // Find a sheet with headers
    const sheetWithHeaders = sheetsInfo.find(sheet => 
      !sheet.isNewFormat && 
      sheet.headers && 
      sheet.headers.length > 0
    );
    
    if (!sheetWithHeaders) return;
    
    const { headers } = sheetWithHeaders;
    
    // Common column names
    const hebrewColumnMap = {
      branch: ['סניף', 'שם סניף', 'סניפים'],
      item: ['פריט', 'מוצר', 'שם פריט', 'תיאור פריט'],
      barcode: ['ברקוד', 'בר קוד', 'מק״ט', 'מקט'],
      packageFactor: ['גורם אירוז', 'גורם אירוז מומלץ', 'כמות באריזה']
    };
    
    // Try to find matching columns
    for (const header of headers) {
      if (hebrewColumnMap.branch.some(col => header.includes(col)) && !branchColumn) {
        setBranchColumn(header);
      }
      if (hebrewColumnMap.item.some(col => header.includes(col)) && !itemColumn) {
        setItemColumn(header);
      }
      if (hebrewColumnMap.barcode.some(col => header.includes(col)) && !barcodeColumn) {
        setBarcodeColumn(header);
      }
      if (hebrewColumnMap.packageFactor.some(col => header.includes(col)) && !packageFactorColumn) {
        setPackageFactorColumn(header);
      }
    }
  };

  const handleProcess = async () => {
    // Validate based on format
    if (isNewFormat) {
      if (!branchIdColumn || !branchNameColumn || !newPackageFactorColumn) {
        toast.error('נא לבחור עמודות חובה: מספר סניף, שם סניף וכמות גורמי אירוז');
        return;
      }
    } else {
      if (!branchColumn || !itemColumn || !packageFactorColumn) {
        toast.error('נא לבחור עמודות חובה: סניף, פריט וגורם אירוז');
        return;
      }
    }
    
    if (selectedSheets.length === 0) {
      toast.error('נא לבחור לפחות גיליון אחד לעיבוד');
      return;
    }

    if (!file) {
      toast.error('נא להעלות קובץ');
      return;
    }

    setProcessingFile(true);
    try {
      const token = localStorage.getItem('token');
      
      // Create FormData and append the file directly
      const formData = new FormData();
      formData.append('file', file);
      
      // Add all other parameters
      formData.append('selectedSheets', JSON.stringify(selectedSheets));
      formData.append('timeLimit', timeLimit);
      formData.append('batchName', batchName || `חלוקה ${new Date().toLocaleDateString('he-IL')}`);
      
      // Add format-specific fields
      if (isNewFormat) {
        formData.append('branchIdColumn', branchIdColumn);
        formData.append('branchNameColumn', branchNameColumn);
        formData.append('packageFactorColumn', newPackageFactorColumn);
      } else {
        formData.append('branchColumn', branchColumn);
        formData.append('itemColumn', itemColumn);
        formData.append('barcodeColumn', barcodeColumn);
        formData.append('packageFactorColumn', packageFactorColumn);
      }

      // Important: Do not set Content-Type header, let axios set it automatically with boundary
      const response = await axios.post(
        `${API_URL}/api/distributions/process`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      toast.success(`נוצרו ${response.data.forms.length} טפסי חלוקה בהצלחה`);
      setShowColumnSelection(false);
      setSheetsInfo([]);
      setFile(null);
      
      // Reset form
      document.getElementById('upload-form')?.reset();
      
      // Redirect to distributions list using the correct path
      window.location.href = '/dashboard/distributions';
      
    } catch (error) {
      console.error('Process error:', error);
      let errorMessage = 'שגיאה בעיבוד הקובץ';
      if (error.response) {
        console.log('Error response:', error.response.data);
        errorMessage = error.response.data.message || errorMessage;
      }
      toast.error(errorMessage);
    } finally {
      setProcessingFile(false);
    }
  };
  
  const toggleSheetSelection = (sheetName) => {
    const newSelection = selectedSheets.includes(sheetName)
      ? selectedSheets.filter(name => name !== sheetName)
      : [...selectedSheets, sheetName];
    
    setSelectedSheets(newSelection);
    
    // Update selectAll state
    setSelectAllSheets(newSelection.length === sheetsInfo.length);
  };
  
  const toggleSelectAllSheets = () => {
    if (selectAllSheets) {
      // Deselect all
      setSelectedSheets([]);
      setSelectAllSheets(false);
    } else {
      // Select all
      setSelectedSheets(sheetsInfo.map(sheet => sheet.name));
      setSelectAllSheets(true);
    }
  };

  return (
    <div className="p-6">
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">העלאת קובץ חלוקות</h2>

        <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 mb-6">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <Info className="h-5 w-5 text-blue-500 ml-2" />
              <h3 className="font-medium text-blue-700">הנחיות להכנת קובץ אקסל</h3>
            </div>
            <button 
              onClick={() => setShowInstructions(!showInstructions)}
              className="text-blue-600 hover:text-blue-800"
            >
              {showInstructions ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
          
          {showInstructions && (
            <div className="text-blue-800 text-sm space-y-2 mt-2">
              <p><strong>המערכת תומכת בשני מבנים שונים של קבצי אקסל:</strong></p>
              
              <div className="border-r-4 border-blue-300 pr-3 mb-3">
                <p><strong>מבנה 1: מבנה קלאסי - מספר מוצרים לסניף</strong></p>
                <ul className="list-disc pr-5 space-y-1">
                  <li>
                    <strong>שורה ראשונה:</strong> כותרות העמודות (שמות השדות).
                  </li>
                  <li>
                    <strong>עמודת סניף:</strong> חובה - שם הסניף. חשוב שהשם יהיה זהה לשם הסניף במערכת.
                  </li>
                  <li>
                    <strong>עמודת פריט:</strong> חובה - שם המוצר.
                  </li>
                  <li>
                    <strong>עמודת ברקוד:</strong> מומלץ - מק"ט או ברקוד המוצר.
                  </li>
                  <li>
                    <strong>עמודת גורם אירוז:</strong> חובה - מספר יחידות באריזה (מספר בלבד).
                  </li>
                </ul>
              </div>
              
              <div className="border-r-4 border-green-300 pr-3">
                <p><strong>מבנה 2: מבנה חדש - מוצר בודד לכל גיליון</strong></p>
                <ul className="list-disc pr-5 space-y-1">
                  <li>
                    <strong>בראש הגיליון:</strong> מידע על המוצר (פריט, ברקוד, שם, ספק).
                  </li>
                  <li>
                    <strong>טבלת חלוקה לסניפים:</strong> עם העמודות הבאות:
                    <ul className="list-circle pr-5 mt-1">
                      <li><strong>מספר סניף:</strong> חובה - מספר מזהה של הסניף.</li>
                      <li><strong>שם סניף:</strong> חובה - שם הסניף.</li>
                      <li><strong>כמות גורמי אירוז:</strong> חובה - כמות לחלוקה.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>גיליונות מרובים:</strong> ניתן לכלול מספר גיליונות בקובץ, כל אחד עם מוצר שונה.
                  </li>
                </ul>
              </div>
              
              <p className="mt-4"><strong>הערות חשובות:</strong></p>
              <ul className="list-disc pr-5 space-y-1">
                <li>המערכת תזהה אוטומטית את מבנה הקובץ.</li>
                <li>עבור סניפים עם כמות 0, המערכת לא תיצור טפסי חלוקה.</li>
                <li>ודאו כי שמות הסניפים זהים לשמות במערכת, אחרת הסניף לא יזוהה.</li>
              </ul>
            </div>
          )}
        </div>

        <form id="upload-form" onSubmit={handleUpload} className="space-y-4">
          <div className="flex items-center space-x-4">
            <input
              type="file"
              accept=".xlsx,.xls"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
            />
          </div>

          <div className="flex gap-2">
            <button
              type="submit"
              disabled={loading || !file}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {loading ? (
                'מעלה...'
              ) : (
                <>
                  <FileUp className="h-5 w-5 ml-2" />
                  העלאה
                </>
              )}
            </button>
          </div>
        </form>
      </div>

      {showColumnSelection && (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold">
              {isNewFormat ? 'הגדרות לפורמט החדש' : 'בחירת עמודות'}
            </h3>
            <button
              onClick={() => setShowColumnSelection(!showColumnSelection)}
              className="text-gray-500 hover:text-gray-700"
            >
              {showColumnSelection ? (
                <ChevronUp className="h-6 w-6" />
              ) : (
                <ChevronDown className="h-6 w-6" />
              )}
            </button>
          </div>

          {/* Sheet selection */}
          {sheetsInfo.length > 1 && (
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-lg font-medium">בחירת גיליונות לעיבוד</h4>
                <button
                  type="button"
                  onClick={toggleSelectAllSheets}
                  className="text-sm text-blue-600 hover:text-blue-800"
                >
                  {selectAllSheets ? 'בטל בחירת הכל' : 'בחר הכל'}
                </button>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-2">
                {sheetsInfo.map((sheet) => (
                  <div 
                    key={sheet.name}
                    className={`border rounded-md p-2 flex items-center justify-between ${
                      selectedSheets.includes(sheet.name) ? 'bg-blue-50 border-blue-200' : 'border-gray-200'
                    }`}
                    onClick={() => toggleSheetSelection(sheet.name)}
                  >
                    <div className="flex-1">
                      <p className="font-medium">{sheet.name}</p>
                      {isNewFormat && sheet.productInfo && (
                        <p className="text-xs text-gray-500 truncate">
                          {sheet.productInfo.name || 'מוצר ללא שם'}
                          {sheet.productInfo.barcode && ` (${sheet.productInfo.barcode})`}
                        </p>
                      )}
                      {sheet.error && (
                        <p className="text-xs text-red-500">{sheet.error}</p>
                      )}
                    </div>
                    <div className={`w-5 h-5 flex items-center justify-center rounded-md border ${
                      selectedSheets.includes(sheet.name) 
                        ? 'bg-blue-500 border-blue-500 text-white' 
                        : 'border-gray-300'
                    }`}>
                      {selectedSheets.includes(sheet.name) && <Check className="w-4 h-4" />}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="space-y-6">
            {/* Column selection for new format */}
            {isNewFormat && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    עמודת מספר סניף <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={branchIdColumn}
                    onChange={(e) => setBranchIdColumn(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">בחר עמודת מספר סניף</option>
                    {sheetsInfo.length > 0 && 
                      sheetsInfo[0].tableHeaders && 
                      sheetsInfo[0].tableHeaders.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    עמודת שם סניף <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={branchNameColumn}
                    onChange={(e) => setBranchNameColumn(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">בחר עמודת שם סניף</option>
                    {sheetsInfo.length > 0 && 
                      sheetsInfo[0].tableHeaders && 
                      sheetsInfo[0].tableHeaders.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    עמודת כמות גורמי אירוז <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={newPackageFactorColumn}
                    onChange={(e) => setNewPackageFactorColumn(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">בחר עמודת כמות גורמי אירוז</option>
                    {sheetsInfo.length > 0 && 
                      sheetsInfo[0].tableHeaders && 
                      sheetsInfo[0].tableHeaders.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}

            {/* Column selection for old format */}
            {!isNewFormat && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    סניף <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={branchColumn}
                    onChange={(e) => setBranchColumn(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">בחר עמודת סניף</option>
                    {sheetsInfo.length > 0 && 
                      sheetsInfo[0].headers && 
                      sheetsInfo[0].headers.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    פריט <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={itemColumn}
                    onChange={(e) => setItemColumn(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">בחר עמודת פריט</option>
                    {sheetsInfo.length > 0 && 
                      sheetsInfo[0].headers && 
                      sheetsInfo[0].headers.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    ברקוד
                  </label>
                  <select
                    value={barcodeColumn}
                    onChange={(e) => setBarcodeColumn(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">בחר עמודת ברקוד</option>
                    {sheetsInfo.length > 0 && 
                      sheetsInfo[0].headers && 
                      sheetsInfo[0].headers.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    גורם אירוז <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={packageFactorColumn}
                    onChange={(e) => setPackageFactorColumn(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  >
                    <option value="">בחר עמודת גורם אירוז</option>
                    {sheetsInfo.length > 0 && 
                      sheetsInfo[0].headers && 
                      sheetsInfo[0].headers.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}

            {/* Common settings */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  זמן להשלמה (שעות)
                </label>
                <input
                  type="number"
                  min="1"
                  max="72"
                  value={timeLimit}
                  onChange={(e) => setTimeLimit(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  שם הקבוצה
                </label>
                <input
                  type="text"
                  value={batchName}
                  onChange={(e) => setBatchName(e.target.value)}
                  placeholder="שם לקבוצת חלוקות זו"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="pt-4">
              <button
                type="button"
                onClick={handleProcess}
                disabled={processingFile}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
              >
                {processingFile ? (
                  'מעבד...'
                ) : (
                  <>
                    <FileCheck className="h-5 w-5 ml-2" />
                    יצירת טפסים
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 