import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFDownloadLink } from '@react-pdf/renderer';

// Register Hebrew font
Font.register({
  family: 'Rubik',
  src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf'
});

// Create styles with RTL support
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Rubik',
    padding: 30,
    direction: 'rtl',
  },
  header: {
    backgroundColor: '#1e40af',
    padding: 20,
    marginBottom: 20,
    borderRadius: 8,
  },
  title: {
    fontSize: 24,
    textAlign: 'right',
    color: '#ffffff',
    marginBottom: 4,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 16,
    textAlign: 'right',
    color: '#e5e7eb',
    direction: 'rtl',
    writingMode: 'horizontal-tb',
    alignSelf: 'flex-end',
  },
  infoGrid: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: 20,
    padding: 15,
    backgroundColor: '#f8fafc',
    borderRadius: 8,
    border: '1px solid #e2e8f0',
  },
  infoColumn: {
    flex: 1,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e5e7eb',
    marginTop: 20,
  },
  tableRow: {
    flexDirection: 'row-reverse',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e7eb',
    minHeight: 35,
    alignItems: 'center',
  },
  tableHeader: {
    backgroundColor: '#f9fafb',
    fontWeight: 'bold',
  },
  tableCell: {
    padding: 8,
    fontSize: 10,
    textAlign: 'right',
  },
  itemIdCell: {
    width: '15%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  nameCell: {
    width: '45%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  priceCell: {
    width: '20%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  quantityCell: {
    width: '20%',
    borderRightWidth: 1,
    borderRightColor: '#e5e7eb',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    textAlign: 'center',
    color: '#9ca3af',
    fontSize: 10,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    fontSize: 10,
    color: '#9ca3af',
  },
  notes: {
    marginTop: 20,
    padding: 10,
    backgroundColor: '#f9fafb',
    borderRadius: 4,
  },
  total: {
    marginTop: 10,
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 'bold',
  },
  labelContainer: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    marginBottom: 5,
  },
  label: {
    color: '#6b7280',
    fontSize: 12,
    marginLeft: 8,
  },
  value: {
    color: '#111827',
    fontSize: 12,
  },
});

const formatDate = (dateString) => {
  if (!dateString) return '-';
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('he-IL', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  } catch (error) {
    return '-';
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 'approved': return 'אושר';
    case 'rejected': return 'נדחה';
    case 'pending': return 'ממתין לאישור';
    case 'delivered': return 'נמסר';
    default: return status;
  }
};

const InfoLabel = ({ label, value }) => (
  <View style={styles.labelContainer}>
    <Text style={styles.label}>{label}:</Text>
    <Text style={styles.value}>{value}</Text>
  </View>
);

const OfficeSupplyOrderPDF = ({ order }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <View style={{ flexDirection: 'row-reverse', justifyContent: 'space-between', alignItems: 'center' }}>
          <View>
            <Text style={styles.title}>הזמנת ציוד משרדי</Text>
            <Text style={styles.subtitle}>
              {order.branchId && order.branchId.name ? `סניף ${order.branchId.name}` : 'סניף לא ידוע'}
            </Text>
          </View>
          <View style={{ backgroundColor: 'rgba(255,255,255,0.1)', padding: 8, borderRadius: 4 }}>
            <Text style={{ color: '#ffffff', fontSize: 12 }}>
              {formatDate(order.submittedAt)}
            </Text>
          </View>
        </View>
      </View>

      {/* Order Info */}
      <View style={styles.infoGrid}>
        <View style={styles.infoColumn}>
          <InfoLabel label="מספר הזמנה" value={order.orderNumber || order._id.substring(order._id.length - 8)} />
          <InfoLabel label="סטטוס" value={getStatusText(order.status)} />
        </View>
        <View style={styles.infoColumn}>
          <InfoLabel 
            label="שם המזמין" 
            value={order.requesterName || (order.submittedBy ? order.submittedBy.fullName : 'לא ידוע')} 
          />
          <InfoLabel 
            label="טלפון המזמין" 
            value={order.requesterPhone || 'לא צוין'} 
          />
          <InfoLabel label="תאריך הזמנה" value={formatDate(order.submittedAt)} />
        </View>
      </View>

      {/* Items Table */}
      <View style={styles.table}>
        {/* Table Header */}
        <View style={[styles.tableRow, styles.tableHeader]}>
          <View style={[styles.tableCell, styles.itemIdCell]}>
            <Text>מק"ט</Text>
          </View>
          <View style={[styles.tableCell, styles.nameCell]}>
            <Text>שם פריט</Text>
          </View>
          <View style={[styles.tableCell, styles.priceCell]}>
            <Text>מחיר</Text>
          </View>
          <View style={[styles.tableCell, styles.quantityCell]}>
            <Text>כמות</Text>
          </View>
        </View>

        {/* Table Body */}
        {order.items.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={[styles.tableCell, styles.itemIdCell]}>
              <Text>{item.itemId}</Text>
            </View>
            <View style={[styles.tableCell, styles.nameCell]}>
              <Text>{item.name}</Text>
            </View>
            <View style={[styles.tableCell, styles.priceCell]}>
              <Text>₪{item.price.toFixed(2)}</Text>
            </View>
            <View style={[styles.tableCell, styles.quantityCell]}>
              <Text>{item.quantity}</Text>
            </View>
          </View>
        ))}
      </View>

      {/* Total */}
      <View style={styles.total}>
        <Text>סה"כ: ₪{order.totalAmount.toFixed(2)}</Text>
      </View>

      {/* Notes */}
      {order.notes && (
        <View style={styles.notes}>
          <Text style={{ fontSize: 12, color: '#4b5563' }}>הערות:</Text>
          <Text style={{ fontSize: 10, marginTop: 4 }}>{order.notes}</Text>
        </View>
      )}

      {/* Footer */}
      <Text style={styles.footer}>
        הופק בתאריך: {formatDate(new Date())}
      </Text>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
);

// Wrapper component with download link
const OfficeSupplyOrderPDFGenerator = ({ order }) => (
  <PDFDownloadLink 
    document={<OfficeSupplyOrderPDF order={order} />} 
    fileName={`order-${order._id}.pdf`}
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      padding: '8px 16px',
      backgroundColor: '#1e40af',
      color: 'white',
      borderRadius: '4px',
      textDecoration: 'none',
      fontSize: '14px',
      cursor: 'pointer'
    }}
  >
    {({ blob, url, loading, error }) => 
      loading ? 'מכין מסמך...' : 'הורד הזמנה'
    }
  </PDFDownloadLink>
);

export { OfficeSupplyOrderPDF, OfficeSupplyOrderPDFGenerator };
export default OfficeSupplyOrderPDFGenerator; 