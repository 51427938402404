import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { getOfficeSupplyItems, createOfficeSupplyOrder } from '../../utils/api';

const BranchOfficeSuppliesOrder = () => {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const data = await getOfficeSupplyItems();
      setItems(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching items:', error);
      toast.error('שגיאה בטעינת פריטים');
      setLoading(false);
    }
  };

  const handleQuantityChange = (itemId, quantity) => {
    const numericQuantity = parseInt(quantity) || 0;
    
    if (numericQuantity < 0) return;
    if (numericQuantity > 1000) {
      toast.error('כמות מקסימלית להזמנה היא 1000');
      return;
    }

    setSelectedItems(prev => {
      const existing = prev.find(item => item.itemId === itemId);
      if (existing) {
        if (numericQuantity === 0) {
          return prev.filter(item => item.itemId !== itemId);
        }
        return prev.map(item => 
          item.itemId === itemId 
            ? { ...item, quantity: numericQuantity }
            : item
        );
      }
      if (numericQuantity > 0) {
        const item = items.find(i => i.itemId === itemId);
        return [...prev, {
          itemId: item.itemId,
          name: item.name,
          price: item.price,
          quantity: numericQuantity
        }];
      }
      return prev;
    });
  };

  const calculateTotal = () => {
    return selectedItems.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedItems.length === 0) {
      toast.error('נא לבחור לפחות פריט אחד');
      return;
    }

    setSubmitting(true);
    try {
      const orderData = {
        items: selectedItems,
        notes,
        totalAmount: calculateTotal()
      };

      await createOfficeSupplyOrder(orderData);
      toast.success('ההזמנה נשלחה בהצלחה');
      setSelectedItems([]);
      setNotes('');
    } catch (error) {
      console.error('Error submitting order:', error);
      toast.error('שגיאה בשליחת ההזמנה');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h1 className="text-2xl font-bold mb-4">הזמנת ציוד משרדי</h1>
        <form onSubmit={handleSubmit}>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מק"ט</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">שם פריט</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מחיר</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">כמות</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סה"כ</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {items.map((item) => {
                  const selectedItem = selectedItems.find(si => si.itemId === item.itemId);
                  const quantity = selectedItem?.quantity || 0;
                  const total = quantity * item.price;

                  return (
                    <tr key={item.itemId} className={quantity > 0 ? 'bg-blue-50' : ''}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.itemId}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">₪{item.price.toFixed(2)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="number"
                          min="0"
                          max="1000"
                          value={quantity || ''}
                          onChange={(e) => handleQuantityChange(item.itemId, e.target.value)}
                          className="w-20 px-2 py-1 border rounded-md"
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {total > 0 && `₪${total.toFixed(2)}`}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot className="bg-gray-50">
                <tr>
                  <td colSpan="4" className="px-6 py-4 text-right font-bold">סה"כ להזמנה:</td>
                  <td className="px-6 py-4 font-bold">₪{calculateTotal().toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </div>

          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">הערות להזמנה</label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="w-full px-3 py-2 border rounded-md"
              rows="3"
              placeholder="הערות נוספות להזמנה..."
            />
          </div>

          <div className="mt-6 flex justify-end">
            <button
              type="submit"
              disabled={submitting || selectedItems.length === 0}
              className={`px-6 py-2 rounded-md text-white font-medium ${
                submitting || selectedItems.length === 0
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
              }`}
            >
              {submitting ? 'שולח...' : 'שלח הזמנה'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BranchOfficeSuppliesOrder; 