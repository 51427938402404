const getApiUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    // In production, we use the environment variable or empty string (for same-origin requests)
    return process.env.REACT_APP_API_URL || '';
  }
  // In development, also fallback to empty string for same-origin requests
  return process.env.REACT_APP_API_URL || '';
};

export const API_URL = getApiUrl();

console.log('Current API_URL:', API_URL);
