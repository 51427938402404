import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Homepage from './components/Homepage';
import Login from './components/Login';
import ModernDashboardLayout from './components/ModernDashboardLayout';
import { selectUser, logout } from './store/slices/authSlice';  // Add this import
import PublicSalaryUpgradeForm from './components/SalaryUpgrade/PublicSalaryUpgradeForm';
import CashierGuide from './components/CashierGuide/CashierGuide';
import CalculatorSwitcher from './components/PayrollCalculator/CalculatorSwitcher';
import RejectionForm from './components/RejectionForms/RejectionForm';
import SalesPractice from './components/SalesPractice/SalesPractice';
import WigCalculatorSwitcher from './components/WigCalculator/WigCalculatorSwitcher';
import YearSummary from './components/YearSummary/YearSummary';
import PublicInventoryForm from './components/inventory/PublicInventoryForm';
import PublicOfficeSuppliesOrder from './components/OfficeSupplies/PublicOfficeSuppliesOrder';
import DistributionForm from './components/distributions/DistributionForm';
import BatchDistributionForm from './components/distributions/BatchDistributionForm';
import BranchDistributionForms from './components/distributions/BranchDistributionForms';

const PrivateRoute = ({ children }) => {
  const user = useSelector(selectUser);
  return user ? children : <Navigate to="/login" replace />;
};

function App() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const handleLogout = () => {  // Add this function back
    console.log('Logging out');
    dispatch(logout());
    localStorage.removeItem('user');
    localStorage.removeItem('loginTimestamp');
  };

  return (
    <Router>
      <Toaster position="top-center" reverseOrder={false} />
      
      <Routes>
        <Route 
          path="/" 
          element={user ? <Navigate to="/dashboard" replace /> : <Homepage />} 
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/dashboard" replace /> : <Login />}
        />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <ModernDashboardLayout user={user} onLogout={handleLogout} />
            </PrivateRoute>
          }
        />
        <Route path="/salary-survey" element={<PublicSalaryUpgradeForm />} />
        <Route path="/cashier-guide" element={<CashierGuide />} />
        <Route path="/payroll-calculator" element={<CalculatorSwitcher />} />
        <Route path="/rejection-form/:token" element={<RejectionForm />} />
        <Route path="/inventory/form/:token" element={<PublicInventoryForm />} />
        <Route path="/distribution-forms" element={<Navigate to="/" replace />} />
        <Route path="/distribution-form/batch/:batchId" element={<BatchDistributionForm />} />
        <Route path="/distribution-form/branch/:branchId" element={<BranchDistributionForms />} />
        <Route path="/distribution-form/:token" element={<DistributionForm />} />
        <Route path="/practice" element={<SalesPractice />} />
        <Route path="/wig-calculator" element={<WigCalculatorSwitcher />} />
        <Route path="/2024" element={<YearSummary />} />
        <Route path="/office-supplies-order" element={<PublicOfficeSuppliesOrder />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;