import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Download, Filter, Search } from 'lucide-react';
import { getGiftCards, getAreaManagerGiftCards, exportGiftCards, getBranches, getAreaManagerBranches } from '../../utils/api';

const hebrewMonths = [
  { value: "01", label: "ינואר" },
  { value: "02", label: "פברואר" },
  { value: "03", label: "מרץ" },
  { value: "04", label: "אפריל" },
  { value: "05", label: "מאי" },
  { value: "06", label: "יוני" },
  { value: "07", label: "יולי" },
  { value: "08", label: "אוגוסט" },
  { value: "09", label: "ספטמבר" },
  { value: "10", label: "אוקטובר" },
  { value: "11", label: "נובמבר" },
  { value: "12", label: "דצמבר" }
];

const GiftCardList = forwardRef((props, ref) => {
  const [giftCards, setGiftCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [branchesLoading, setBranchesLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [selectedBranch, setSelectedBranch] = useState('all');
  const user = useSelector(state => state.auth.user);
  const isAdmin = user.role === 'admin';

  const fetchBranches = async () => {
    try {
      setBranchesLoading(true);
      const response = await (isAdmin ? getBranches() : getAreaManagerBranches());
      console.log('Branches response:', response);
      
      // For admin, response is direct array, for area manager it's in data property
      const branchesData = isAdmin ? response : response.data;
      
      if (!Array.isArray(branchesData)) {
        console.error('Invalid branches response:', response);
        toast.error('שגיאה בטעינת סניפים');
        setBranches([]);
        return;
      }
      
      setBranches(branchesData);
    } catch (error) {
      console.error('Error fetching branches:', error);
      toast.error('שגיאה בטעינת סניפים');
      setBranches([]);
    } finally {
      setBranchesLoading(false);
    }
  };

  const fetchGiftCards = async () => {
    try {
      const response = await (isAdmin ? getGiftCards() : getAreaManagerGiftCards());
      let filteredCards = response.data;

      // Apply month filter
      if (selectedMonth !== 'all') {
        filteredCards = filteredCards.filter(card => {
          const cardMonth = new Date(card.submittedAt).getMonth() + 1;
          const cardMonthStr = cardMonth.toString().padStart(2, '0');
          return cardMonthStr === selectedMonth;
        });
      }

      // Apply branch filter
      if (selectedBranch !== 'all') {
        filteredCards = filteredCards.filter(card => card.branchId._id === selectedBranch);
      }

      setGiftCards(filteredCards);
    } catch (error) {
      console.error('Error fetching gift cards:', error);
      toast.error('שגיאה בטעינת כרטיסי המתנה');
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    fetchGiftCards
  }));

  useEffect(() => {
    fetchBranches();
    fetchGiftCards();
  }, [isAdmin]);

  // Fetch gift cards when filters change
  useEffect(() => {
    fetchGiftCards();
  }, [selectedMonth, selectedBranch]);

  const handleExport = async () => {
    try {
      // Add both year and month to the query parameter
      const year = new Date().getFullYear();
      const monthParam = selectedMonth === 'all' ? '' : `${year}-${selectedMonth}`;
      const response = await exportGiftCards(monthParam);
      const blob = new Blob([response], { 
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fileName = selectedMonth === 'all'
        ? `gift-cards-all-${new Date().toISOString().split('T')[0]}.xlsx`
        : `gift-cards-${year}-${selectedMonth}.xlsx`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      toast.success('הקובץ הורד בהצלחה');
    } catch (error) {
      console.error('Error exporting gift cards:', error);
      toast.error('שגיאה בייצוא כרטיסי המתנה');
    }
  };

  if (loading) {
    return (
      <div className="sales-card p-6 rounded-xl shadow-lg animate-fade-in flex justify-center items-center min-h-[200px]">
        <div className="flex items-center gap-3">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
          <span className="text-gray-600 font-medium">טוען...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="sales-card p-6 rounded-xl shadow-lg animate-fade-in">
      <div className="mb-6 flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold text-right text-gray-800">כרטיסי מתנה</h2>
          {isAdmin && (
            <div className="flex items-center gap-2">
              <button
                onClick={handleExport}
                className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors duration-200"
                title="ייצא לאקסל את רשימת כרטיסי המתנה לחודש הנבחר"
              >
                <Download className="h-5 w-5" />
                <span>ייצוא לאקסל</span>
              </button>
            </div>
          )}
        </div>

        {/* Filters Section */}
        <div className="flex items-center gap-4 bg-white/50 p-3 rounded-lg">
          <div className="flex items-center gap-2 text-gray-600">
            <Filter className="h-5 w-5" />
            <span>סינון:</span>
          </div>

          {/* Branch Filter */}
          <div className="relative">
            <select
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              className="block w-40 rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
              style={{ backgroundPosition: "left 0.5rem center" }}
              disabled={branchesLoading}
            >
              <option value="all">כל הסניפים</option>
              {(branches || []).map(branch => (
                <option key={branch._id} value={branch._id}>
                  {branch.name}
                </option>
              ))}
            </select>
            {branchesLoading && (
              <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
              </div>
            )}
          </div>

          {/* Month Filter */}
          <div className="relative">
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className="block w-40 rounded-lg border-0 bg-white shadow-md focus:ring-2 focus:ring-blue-500 pl-10 pr-3 py-2.5 text-right transition-all duration-300 appearance-none"
              style={{ backgroundPosition: "left 0.5rem center" }}
            >
              <option value="all">כל החודשים</option>
              {hebrewMonths.map(month => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 bg-white/50">
          <thead>
            <tr className="bg-gray-50/80">
              <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                תאריך הגשה
              </th>
              <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                מספר עובד
              </th>
              <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                שם העובד
              </th>
              <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                סניף
              </th>
              {isAdmin && (
                <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                  הוגש על ידי
                </th>
              )}
              <th scope="col" className="px-6 py-4 text-right text-sm font-bold text-gray-700 tracking-wider">
                הערות
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white/30">
            {giftCards.map((card, index) => (
              <tr 
                key={card._id}
                className="hover:bg-blue-50/50 transition-colors duration-200"
              >
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                  {new Date(card.submittedAt).toLocaleDateString('he-IL')}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                  {card.workerId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800 font-medium">
                  {card.workerName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                  {card.branchId.name}
                </td>
                {isAdmin && (
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800">
                    {card.submittedBy.fullName}
                  </td>
                )}
                <td className="px-6 py-4 text-right text-sm text-gray-800">
                  {card.notes}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
});

export default GiftCardList;
