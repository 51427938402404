import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Building, ChevronLeft, FileText } from 'lucide-react';
import { API_URL } from '../../config';

export default function BranchDistributionForms() {
  const { branchId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [forms, setForms] = useState([]);
  const [branchName, setBranchName] = useState('');
  const [loading, setLoading] = useState(true);

  // Get the batch ID from the state if it exists (passed during navigation)
  const fromBatchId = location.state?.fromBatchId;

  useEffect(() => {
    const fetchForms = async () => {
      try {
        // Fetch forms for this branch
        const formsResponse = await axios.get(`${API_URL}/api/distributions/branch-forms/${branchId}`);
        setForms(formsResponse.data);

        // Fetch branch details
        const branchResponse = await axios.get(`${API_URL}/api/distributions/public/branches`);
        const branch = branchResponse.data.find(b => b._id === branchId);
        if (branch) {
          setBranchName(branch.name);
        }
      } catch (error) {
        console.error('Error fetching forms:', error);
        toast.error('שגיאה בטעינת הטפסים');
      } finally {
        setLoading(false);
      }
    };

    fetchForms();
  }, [branchId]);

  const handleFormSelect = (accessToken) => {
    navigate(`/distribution-form/${accessToken}`);
  };

  const handleBackClick = () => {
    // If we know this was navigated from a batch form, go back to it
    if (fromBatchId) {
      navigate(`/distribution-form/batch/${fromBatchId}`);
    } else {
      // Otherwise, go to the homepage since we don't have UnifiedDistributionForm anymore
      navigate('/');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50" dir="rtl">
      <div className="max-w-4xl mx-auto p-2 sm:p-6">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-4">
            <div className="flex items-center justify-between mb-2">
              <button
                onClick={handleBackClick}
                className="flex items-center text-blue-100 hover:text-white transition-colors"
              >
                <ChevronLeft className="h-5 w-5 ml-1" />
                {fromBatchId ? 'חזרה לבחירת סניף' : 'חזרה לדף הבית'}
              </button>
              <div className="flex items-center">
                <Building className="h-5 w-5 ml-2" />
                <h1 className="text-lg sm:text-2xl font-bold">{branchName}</h1>
              </div>
            </div>
            <p className="text-sm text-blue-100 text-center">טפסי חלוקות סחורה זמינים</p>
          </div>
        </div>

        {/* Forms List */}
        <div className="mt-4 space-y-4">
          {forms.length === 0 ? (
            <div className="text-center py-10 bg-white rounded-lg shadow">
              <p className="text-gray-500">אין טפסי חלוקה זמינים כרגע</p>
            </div>
          ) : (
            forms.map((form) => (
              <button
                key={form._id}
                onClick={() => handleFormSelect(form.accessToken)}
                className="w-full bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-start">
                    <FileText className="h-5 w-5 text-blue-600 ml-2 mt-1" />
                    <div className="text-right">
                      <h3 className="font-medium text-gray-900">{form.title}</h3>
                      <p className="text-sm text-gray-500 mt-1">
                        מספר פריטים: {form.items.length}
                      </p>
                      <p className="text-sm text-gray-500">
                        תאריך תפוגה: {new Date(form.expiresAt).toLocaleDateString('he-IL')}
                      </p>
                    </div>
                  </div>
                  <span className="px-2 py-1 text-xs rounded-full bg-yellow-100 text-yellow-800">
                    ממתין למילוי
                  </span>
                </div>
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
} 