import axios from 'axios';
import { store } from '../store';
import { selectToken, logout } from '../store/slices/authSlice';

// Fix the API_URL to prevent double URL issues
const API_URL = process.env.REACT_APP_API_URL || '';

// List of public endpoints that don't require authentication
const publicEndpoints = [
  '/api/inventory/access/',
  '/api/inventory/upload-image/',
  '/api/rejection-forms/access/',
  '/api/office-supplies/public/'
];

// Create axios instance with default config
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Client-side cache configuration
const CACHE_CONFIG = {
  BRANCHES_TTL: 5 * 60 * 1000, // 5 minutes
  ADMIN_BRANCHES_KEY: 'admin_branches_cache',
  AREA_MANAGER_BRANCHES_KEY: 'area_manager_branches_cache'
};

/**
 * Request Interceptor
 * - Handles authentication
 * - Manages FormData
 * - Processes dates
 */
api.interceptors.request.use(
  (config) => {
    // Check if the request URL is a public endpoint
    const isPublicEndpoint = publicEndpoints.some(endpoint => 
      config.url.includes(endpoint)
    );
    
    // Only add token for non-public endpoints
    if (!isPublicEndpoint) {
      const token = selectToken(store.getState());
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    
    // Handle FormData separately
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    } else {
      // For regular JSON data, ensure dates are properly formatted
      if (typeof config.data === 'object' && config.data !== null) {
        const processedData = { ...config.data };
        
        // Convert date strings to ISO format for consistent handling
        if (processedData.reportDate) {
          processedData.reportDate = new Date(processedData.reportDate).toISOString();
        }
        
        config.data = processedData;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Response Interceptor
 * - Handles token expiration
 * - Manages authentication errors
 */
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // If token is expired or invalid, dispatch logout
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

// ============================================================================
// Branch Management
// ============================================================================

const getBranches = async () => {
  try {
    // Try to get from localStorage first
    const cachedData = localStorage.getItem(CACHE_CONFIG.ADMIN_BRANCHES_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const age = Date.now() - timestamp;
      
      // Return cached data if it's still valid
      if (age < CACHE_CONFIG.BRANCHES_TTL) {
        console.log('Using cached branches data');
        return data;
      }
      // Clear expired cache
      localStorage.removeItem(CACHE_CONFIG.ADMIN_BRANCHES_KEY);
    }

    // Fetch from server if no valid cache
    console.log('Fetching branches from server...');
    const response = await api.get('/api/inventory/branches');
    console.log('Branches response:', response);
    
    const branches = response.data;

    // Cache the new data
    localStorage.setItem(CACHE_CONFIG.ADMIN_BRANCHES_KEY, JSON.stringify({
      data: branches,
      timestamp: Date.now()
    }));

    return branches;
  } catch (error) {
    console.error('Error fetching branches:', error);
    if (error.response?.status === 403) {
      console.error('Authentication error - token might be invalid');
      store.dispatch(logout());
    }
    throw error;
  }
};

const getAreaManagerBranches = async () => {
  try {
    // Get auth token from store
    const state = store.getState();
    const token = selectToken(state);
    const user = state.auth?.user;
    
    if (!token) {
      throw new Error('No authentication token available');
    }

    if (user?.role !== 'area_manager') {
      throw new Error('Only area managers can access this endpoint');
    }

    // Try to get from localStorage first
    const cachedData = localStorage.getItem(CACHE_CONFIG.AREA_MANAGER_BRANCHES_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const age = Date.now() - timestamp;
      
      // Return cached data if it's still valid
      if (age < CACHE_CONFIG.BRANCHES_TTL) {
        console.log('Using client-side cached area manager branches');
        return { data };
      }
      // Clear expired cache
      localStorage.removeItem(CACHE_CONFIG.AREA_MANAGER_BRANCHES_KEY);
    }

    // Fetch from server if no valid cache
    console.log('Fetching area manager branches...');
    const response = await api.get('/api/area-manager/branches', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    console.log('Area manager branches response:', response);
    const branches = response.data?.data || [];
    
    // Cache the new data
    localStorage.setItem(CACHE_CONFIG.AREA_MANAGER_BRANCHES_KEY, JSON.stringify({
      data: branches,
      timestamp: Date.now()
    }));

    return { data: branches };
  } catch (error) {
    console.error('Error fetching area manager branches:', error);
    if (error.response?.status === 403) {
      console.error('Authentication error - token might be invalid');
      store.dispatch(logout());
    }
    throw error;
  }
};

// ============================================================================
// Inventory Management
// ============================================================================

const validateInventoryFormData = (data) => {
  if (!data.title) throw new Error('נדרש להזין כותרת');
  if (!data.branch) throw new Error('נדרש לבחור סניף');
  if (!data.questions || !data.questions.length) throw new Error('נדרש להזין לפחות שאלה אחת');
  if (!data.timeLimit) throw new Error('נדרש להזין זמן להשלמה');
  
  // Validate time limit
  const timeLimit = parseInt(data.timeLimit);
  if (isNaN(timeLimit) || timeLimit < 1 || timeLimit > 72) {
    throw new Error('זמן להשלמה חייב להיות בין שעה ל-72 שעות');
  }
  
  return true;
};

const getInventoryForm = async (token) => {
  try {
    const response = await api.get(`/api/inventory/access/${token}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching form:', error);
    throw error;
  }
};

const submitInventoryResponse = async (token, answers) => {
  try {
    const response = await api.post(`/api/inventory/access/${token}/submit`, { answers });
    return response.data;
  } catch (error) {
    console.error('Error submitting response:', error);
    throw error;
  }
};

const uploadInventoryImage = async (token, formData) => {
  try {
    const response = await api.post(
      `/api/inventory/upload-image/${token}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 30000,
        maxContentLength: 10 * 1024 * 1024
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

const createInventoryForm = async (formData) => {
  try {
    validateInventoryFormData(formData);
    // Ensure timeLimit is sent as a number
    const data = {
      ...formData,
      timeLimit: parseInt(formData.timeLimit)
    };
    const response = await api.post('/api/inventory', data);
    return response.data;
  } catch (error) {
    console.error('Error creating form:', error);
    throw error;
  }
};

const getBranchInventoryForms = async (branchId, areaManagerId, status, page = 1, limit = 50) => {
  try {
    // If a specific branch is selected, use the branch-specific endpoint
    if (branchId) {
      const response = await api.get(`/api/inventory/branch/${branchId}`);
      return response.data;
    }
    
    // For admin/manager without specific branch, get all forms
    const queryParams = new URLSearchParams();
    if (areaManagerId) queryParams.append('areaManagerId', areaManagerId);
    if (status) queryParams.append('status', status);
    queryParams.append('page', page);
    queryParams.append('limit', limit);
    
    const response = await api.get(
      `/api/inventory${queryParams.toString() ? `?${queryParams.toString()}` : ''}`
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching inventory forms:', error);
    throw error;
  }
};

const getInventoryStats = async () => {
  try {
    const response = await api.get('/api/inventory/stats');
    return response.data;
  } catch (error) {
    console.error('Error fetching stats:', error);
    throw error;
  }
};

const getInventoryFormDetails = async (formId) => {
  try {
    const response = await api.get(`/api/inventory/${formId}/details`);
    return response.data;
  } catch (error) {
    console.error('Error fetching form details:', error);
    throw error;
  }
};

const updateFormStatus = async (formId, status) => {
  try {
    const response = await api.patch(
      `/api/inventory/${formId}/status`,
      { status }
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

const updateFormExpiration = async (formId, timeLimit) => {
  try {
    const response = await api.patch(
      `/api/inventory/${formId}/expiration`,
      { timeLimit }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating form expiration:', error);
    throw error;
  }
};

const updateFormQuestions = async (formId, questions) => {
  try {
    const response = await api.patch(
      `/api/inventory/${formId}/questions`,
      { questions }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating form questions:', error);
    throw error;
  }
};

// ============================================================================
// Rejection Forms
// ============================================================================

const validateFormData = (data) => {
  if (!data.branch) throw new Error('נדרש לבחור סניף');
  if (!data.reportDate) throw new Error('נדרש להזין תאריך');
  if (!data.items || !data.items.length) throw new Error('נדרש להזין לפחות פריט אחד');
  
  // Validate dates
  const reportDate = new Date(data.reportDate);
  
  if (isNaN(reportDate.getTime())) throw new Error('תאריך לא תקין');
  if (reportDate > new Date()) throw new Error('תאריך הדיווח לא יכול להיות בעתיד');
  
  return true;
};

const createRejectionForm = async (formData) => {
  try {
    validateFormData(formData);
    const response = await api.post('/api/rejection-forms', formData);
    return response.data;
  } catch (error) {
    console.error('Form creation error:', error);
    throw error;
  }
};

const uploadImage = async (formData, token) => {
  try {
    const response = await api.post(
      `/api/rejection-forms/upload-image/${token}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 30000,
        maxContentLength: 100 * 1024 * 1024,
      }
    );

    if (!response.data.url) {
      throw new Error('No image URL in response');
    }

    return response.data.url;
  } catch (error) {
    console.error('Image upload error:', error);
    throw error;
  }
};

// ============================================================================
// Dashboard
// ============================================================================

const getDashboardData = async () => {
  try {
    const response = await api.get('/api/dashboard');
    return response.data;
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

// ============================================================================
// Salary Upgrades
// ============================================================================

const getSalaryUpgrades = async () => {
  try {
    const response = await api.get('/api/admin/salary-upgrades');
    return response.data;
  } catch (error) {
    console.error('Error fetching salary upgrades:', error);
    throw error;
  }
};

const getAreaManagerSalaryUpgrades = async () => {
  try {
    const response = await api.get('/api/area-manager/salary-upgrades');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateSalaryUpgradeStatus = async (id, status) => {
  try {
    const response = await api.put(`/api/admin/salary-upgrades/${id}`, { status });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateAreaManagerSalaryUpgradeStatus = async (id, status) => {
  try {
    const response = await api.put(`/api/area-manager/salary-upgrades/${id}`, { status });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const createAreaManagerSalaryUpgrade = async (formData) => {
  try {
    const response = await api.post('/api/area-manager/salary-upgrades', formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const exportSalaryUpgrades = async () => {
  try {
    const response = await api.get('/api/admin/salary-upgrades/export', {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const editSalaryUpgrade = async (id, updateData) => {
  try {
    const response = await api.put(`/api/admin/salary-upgrades/response/${id}`, updateData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// ============================================================================
// Gift Cards
// ============================================================================

const getGiftCards = async () => {
  return api.get('/api/admin/gift-cards');
};

const getAreaManagerGiftCards = async () => {
  return api.get('/api/area-manager/gift-cards');
};

const createGiftCard = async (formData) => {
  return api.post('/api/area-manager/gift-cards', formData);
};

const exportGiftCards = async (month) => {
  try {
    const queryParams = month ? `?month=${month}` : '';
    const response = await api.get(`/api/admin/gift-cards/export${queryParams}`, {
      responseType: 'arraybuffer'
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Export reports
const exportInventoryReport = async (startDate, endDate) => {
  try {
    const response = await api.get(
      `/api/inventory/export-report?startDate=${startDate}&endDate=${endDate}`, 
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    console.error('Error exporting report:', error);
    throw error;
  }
};

const exportAreaManagerReport = async (startDate, endDate) => {
  try {
    const response = await api.get(
      `/api/inventory/export-area-manager-report?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    console.error('Error exporting area manager report:', error);
    throw error;
  }
};

const exportItemsReport = async (startDate, endDate) => {
  try {
    const response = await api.get(
      `/api/inventory/export-items-report?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    console.error('Error exporting items report:', error);
    throw error;
  }
};

const exportQuantityReport = async (startDate, endDate) => {
  try {
    const response = await api.get(
      `/api/inventory/export-quantity-report?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    );
    return response.data;
  } catch (error) {
    console.error('Error exporting quantity report:', error);
    throw error;
  }
};

// ============================================================================
// Office Supplies
// ============================================================================

const getOfficeSupplyItems = async () => {
  try {
    const response = await api.get('/api/office-supplies/items');
    return response.data;
  } catch (error) {
    console.error('Error fetching office supply items:', error);
    throw error;
  }
};

const createOfficeSupplyOrder = async (orderData) => {
  try {
    const response = await api.post('/api/office-supplies/orders', orderData);
    return response.data;
  } catch (error) {
    console.error('Error creating office supply order:', error);
    throw error;
  }
};

const getOfficeSupplyOrders = async (filters = {}) => {
  try {
    const { branchId, areaManager, status } = filters;
    let url = '/api/office-supplies/orders';
    
    // Add query parameters if filters are provided
    const params = new URLSearchParams();
    if (branchId) params.append('branchId', branchId);
    if (areaManager) params.append('areaManager', areaManager);
    if (status) params.append('status', status);
    
    // Append params to URL if any exist
    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }
    
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching office supply orders:', error);
    throw error;
  }
};

const updateOfficeSupplyOrderStatus = async (orderId, status) => {
  try {
    const response = await api.put(`/api/office-supplies/orders/${orderId}`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating office supply order status:', error);
    throw error;
  }
};

// Function to update an entire order (items, notes, total)
const updateOfficeSupplyOrder = async (orderId, orderData) => {
  try {
    console.log('API: Updating order', orderId, 'with data:', orderData);
    const response = await api.put(`/api/office-supplies/orders/${orderId}/edit`, orderData);
    console.log('API: Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating office supply order:', error);
    throw error;
  }
};

// Function to export office supply orders to Excel
const exportOfficeSupplyOrders = async (dateRange, filters = {}) => {
  try {
    const { startDate, endDate } = dateRange;
    const { branchId, areaManager, status } = filters;
    
    // Build query parameters
    const params = new URLSearchParams();
    if (startDate) params.append('startDate', startDate);
    if (endDate) params.append('endDate', endDate);
    if (branchId) params.append('branchId', branchId);
    if (areaManager) params.append('areaManager', areaManager);
    if (status) params.append('status', status);
    
    const queryString = params.toString();
    const url = `/api/office-supplies/orders/export${queryString ? `?${queryString}` : ''}`;
    
    const response = await api.get(url, {
      responseType: 'blob'
    });
    
    return response.data;
  } catch (error) {
    console.error('Error exporting office supply orders:', error);
    throw error;
  }
};

// This function is deprecated and now returns a message to use frontend PDF generation
const downloadOfficeSupplyOrderPdf = async (orderId) => {
  try {
    console.warn('Using downloadOfficeSupplyOrderPdf is deprecated. Use the react-pdf/renderer component instead.');
    
    // Try to call the backend endpoint (which now returns a deprecation message)
    const response = await api.get(`/api/office-supplies/orders/${orderId}/pdf`);
    
    // If we get here, the endpoint returned JSON instead of a PDF
    // This will trigger the warning message for developers
    return {
      deprecated: true,
      message: response.data.message || 'This endpoint is deprecated. Use the frontend PDF generation instead.'
    };
  } catch (error) {
    console.error('Error with downloadOfficeSupplyOrderPdf:', error);
    throw error;
  }
};

// Get all users (admin only)
const getUsers = async () => {
  try {
    const response = await api.get('/api/admin/users');
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

// Get only area managers (admin only)
const getAreaManagers = async () => {
  try {
    const users = await getUsers();
    return users.filter(user => user.role === 'area_manager');
  } catch (error) {
    console.error('Error fetching area managers:', error);
    throw error;
  }
};

// Public Office Supplies API Functions (token-based)
const getPublicOfficeSupplyItems = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/api/office-supplies/public/items`, {
      headers: {
        'order-form-token': token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching public office supply items:', error);
    throw error;
  }
};

const getPublicBranches = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/api/office-supplies/public/branches`, {
      headers: {
        'order-form-token': token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching public branches:', error);
    throw error;
  }
};

const createPublicOfficeSupplyOrder = async (orderData, token) => {
  try {
    const response = await axios.post(`${API_URL}/api/office-supplies/public/orders`, orderData, {
      headers: {
        'order-form-token': token
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating public office supply order:', error);
    throw error;
  }
};

// Export all functions grouped by feature
export {
  api as default,
  API_URL,
  // Branches
  getBranches,
  getAreaManagerBranches,
  // Users
  getUsers,
  getAreaManagers,
  // Inventory
  getInventoryForm,
  submitInventoryResponse,
  uploadInventoryImage,
  createInventoryForm,
  getBranchInventoryForms,
  getInventoryStats,
  getInventoryFormDetails,
  updateFormStatus,
  updateFormExpiration,
  updateFormQuestions,
  // Reports
  exportInventoryReport,
  exportAreaManagerReport,
  exportItemsReport,
  exportQuantityReport,
  // Rejection Forms
  uploadImage,
  validateFormData,
  createRejectionForm,
  // Dashboard
  getDashboardData,
  // Salary Upgrades
  getSalaryUpgrades,
  getAreaManagerSalaryUpgrades,
  updateSalaryUpgradeStatus,
  updateAreaManagerSalaryUpgradeStatus,
  createAreaManagerSalaryUpgrade,
  exportSalaryUpgrades,
  editSalaryUpgrade,
  // Gift Cards
  getGiftCards,
  getAreaManagerGiftCards,
  createGiftCard,
  exportGiftCards,
  // Office Supplies
  getOfficeSupplyItems,
  createOfficeSupplyOrder,
  getOfficeSupplyOrders,
  updateOfficeSupplyOrderStatus,
  updateOfficeSupplyOrder,
  exportOfficeSupplyOrders,
  downloadOfficeSupplyOrderPdf,
  getPublicOfficeSupplyItems,
  getPublicBranches,
  createPublicOfficeSupplyOrder,
};
