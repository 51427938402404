import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { 
  Calendar, Clock, Check, X, Link, ChevronDown, ChevronUp, FileText, 
  Building, Mail, Clipboard, AlertTriangle, CheckCircle2, 
  RefreshCw, Package, Clock8
} from 'lucide-react';
import { API_URL } from '../../config';

export default function DistributionsAreaManager() {
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedBatches, setExpandedBatches] = useState({});
  const [expandedForms, setExpandedForms] = useState({});
  const [branchData, setBranchData] = useState({});
  const [statistics, setStatistics] = useState({
    totalForms: 0,
    pendingForms: 0,
    completedForms: 0,
    expiredForms: 0,
    totalBranches: 0,
    totalProducts: 0,
    batchStats: {}
  });

  useEffect(() => {
    const fetchDistributions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/distributions/area-manager`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data.batches) {
          setBatches(response.data.batches);
          
          // Calculate overall and per-batch statistics
          const stats = {
            totalForms: 0,
            pendingForms: 0,
            completedForms: 0,
            expiredForms: 0,
            totalBranches: new Set(),
            totalProducts: new Set(),
            batchStats: {}
          };
          
          response.data.batches.forEach(batch => {
            // Initialize batch statistics
            const batchStat = {
              totalForms: 0,
              pendingForms: 0,
              completedForms: 0,
              expiredForms: 0,
              branches: new Set(),
              products: new Set(),
              respondedBranches: new Set(),
              pendingBranches: new Set(),
              expiredBranches: new Set()
            };

            batch.forms.forEach(form => {
              // Update overall stats
              stats.totalForms++;
              stats.totalBranches.add(form.branchName);
              form.items.forEach(item => stats.totalProducts.add(item.productName));

              // Update batch-specific stats
              batchStat.totalForms++;
              batchStat.branches.add(form.branchName);
              form.items.forEach(item => batchStat.products.add(item.productName));

              // Track branch status
              if (form.status === 'completed') {
                stats.completedForms++;
                batchStat.completedForms++;
                batchStat.respondedBranches.add(form.branchName);
              } else if (form.status === 'pending') {
                stats.pendingForms++;
                batchStat.pendingForms++;
                batchStat.pendingBranches.add(form.branchName);
              } else if (form.status === 'expired') {
                stats.expiredForms++;
                batchStat.expiredForms++;
                batchStat.expiredBranches.add(form.branchName);
              }
            });

            // Convert Sets to counts for the batch
            stats.batchStats[batch.id] = {
              ...batchStat,
              totalBranches: batchStat.branches.size,
              totalProducts: batchStat.products.size,
              respondedBranchesCount: batchStat.respondedBranches.size,
              pendingBranchesCount: batchStat.pendingBranches.size,
              expiredBranchesCount: batchStat.expiredBranches.size,
              respondedBranches: Array.from(batchStat.respondedBranches),
              pendingBranches: Array.from(batchStat.pendingBranches),
              expiredBranches: Array.from(batchStat.expiredBranches)
            };
          });
          
          setStatistics({
            ...stats,
            totalBranches: stats.totalBranches.size,
            totalProducts: stats.totalProducts.size,
            batchStats: stats.batchStats
          });
          
          if (response.data.batches.length > 0) {
            const initialExpandedState = {};
            initialExpandedState[response.data.batches[0].id] = true;
            setExpandedBatches(initialExpandedState);
          }
        } else {
          const legacyBatch = {
            id: 'legacy',
            name: 'כל החלוקות',
            timestamp: new Date(),
            forms: response.data
          };
          setBatches([legacyBatch]);
          setExpandedBatches({ legacy: true });
        }

        await fetchBranchData(response.data.batches || [response.data]);
      } catch (error) {
        console.error('Error fetching distribution forms:', error);
        toast.error('שגיאה בטעינת טפסי חלוקה');
      } finally {
        setLoading(false);
      }
    };

    fetchDistributions();
  }, []);

  // Fetch branch details including email addresses
  const fetchBranchData = async (batchesData) => {
    try {
      const token = localStorage.getItem('token');
      const uniqueBranchIds = new Set();
      
      // Extract unique branch IDs from all forms in all batches
      batchesData.forEach(batch => {
        const forms = batch.forms || [];
        forms.forEach(form => {
          if (form.branch) {
            uniqueBranchIds.add(form.branch.toString());
          }
        });
      });
      
      if (uniqueBranchIds.size === 0) return;
      
      // Fetch branch details for all branch IDs
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const branchesMap = {};
      response.data.forEach(branch => {
        branchesMap[branch._id] = branch;
      });
      
      setBranchData(branchesMap);
    } catch (error) {
      console.error('Error fetching branch data:', error);
    }
  };

  const toggleBatch = (batchId) => {
    setExpandedBatches(prev => ({
      ...prev,
      [batchId]: !prev[batchId]
    }));
  };

  const toggleForm = (formId) => {
    setExpandedForms(prev => ({
      ...prev,
      [formId]: !prev[formId]
    }));
  };

  const copyAccessLink = (accessToken) => {
    const link = `${window.location.origin}/distribution-form/${accessToken}`;
    navigator.clipboard.writeText(link);
    toast.success('קישור הועתק ללוח');
  };

  const getStatusBadge = (status) => {
    if (status === 'completed') {
      return <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"><Check className="w-3 h-3 mr-1" /> הושלם</span>;
    } else if (status === 'expired') {
      return <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"><X className="w-3 h-3 mr-1" /> פג תוקף</span>;
    }
    return <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"><Clock className="w-3 h-3 mr-1" /> ממתין</span>;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('he-IL');
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit' });
  };

  const getBatchSummary = (batch) => {
    const stats = statistics.batchStats[batch.id] || {};
    return `${stats.totalForms || 0} טפסים, ${stats.completedForms || 0} הושלמו, ${stats.pendingForms || 0} ממתינים`;
  };

  const ProgressCircle = ({ percentage, size = 60, strokeWidth = 5, color }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const dash = (percentage * circumference) / 100;
    const gap = circumference - dash;
    
    return (
      <svg width={size} height={size} className="transform rotate-[-90deg]">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          stroke="#eee"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="transparent"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={[dash, gap].join(' ')}
          strokeLinecap="round"
        />
        <foreignObject x="0" y="0" width={size} height={size}>
          <div className="flex items-center justify-center h-full transform rotate-90">
            <p className="text-sm font-bold">
              {Math.round(percentage)}%
            </p>
          </div>
        </foreignObject>
      </svg>
    );
  };

  const BatchStatistics = ({ batchStats }) => {
    if (!batchStats) return null;
    
    const completionPercentage = batchStats.totalForms > 0 
      ? (batchStats.completedForms / batchStats.totalForms) * 100 
      : 0;
      
    return (
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-4 mb-4">
        <h3 className="font-medium text-lg mb-3">סטטיסטיקה</h3>
        
        <div className="flex flex-wrap -mx-2">
          <div className="px-2 w-full sm:w-1/2 md:w-1/4 mb-4">
            <div className="flex items-center">
              <div className="mr-3">
                <ProgressCircle 
                  percentage={completionPercentage} 
                  color={completionPercentage < 30 ? "#ef4444" : completionPercentage < 70 ? "#f59e0b" : "#22c55e"}
                />
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">השלמה</h4>
                <p className="text-lg font-bold">{batchStats.completedForms} / {batchStats.totalForms}</p>
              </div>
            </div>
          </div>
          
          <div className="px-2 w-full sm:w-1/2 md:w-1/4 mb-4">
            <div className="p-3 border border-blue-100 rounded-lg bg-blue-50">
              <div className="flex items-center">
                <Building className="w-5 h-5 text-blue-500 mr-2" />
                <div>
                  <h4 className="text-sm font-medium text-gray-500">סניפים</h4>
                  <p className="text-lg font-bold">{batchStats.totalBranches}</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="px-2 w-full sm:w-1/2 md:w-1/4 mb-4">
            <div className="p-3 border border-green-100 rounded-lg bg-green-50">
              <div className="flex items-center">
                <CheckCircle2 className="w-5 h-5 text-green-500 mr-2" />
                <div>
                  <h4 className="text-sm font-medium text-gray-500">הושלמו</h4>
                  <p className="text-lg font-bold">{batchStats.completedForms}</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="px-2 w-full sm:w-1/2 md:w-1/4 mb-4">
            <div className="p-3 border border-yellow-100 rounded-lg bg-yellow-50">
              <div className="flex items-center">
                <AlertTriangle className="w-5 h-5 text-yellow-500 mr-2" />
                <div>
                  <h4 className="text-sm font-medium text-gray-500">ממתינים</h4>
                  <p className="text-lg font-bold">{batchStats.pendingForms}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <RefreshCw className="w-8 h-8 text-blue-500 animate-spin" />
        <span className="mr-3 text-lg text-gray-700">טוען נתונים...</span>
      </div>
    );
  }

  if (batches.length === 0) {
    return (
      <div className="bg-white border border-gray-200 rounded-lg shadow-sm p-8 text-center">
        <Package className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 mb-2">אין טפסי חלוקה זמינים</h3>
        <p className="text-gray-500">לא נמצאו טפסי חלוקה עבור הסניפים שבאחריותך</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">חלוקות סחורה</h1>
        <div className="flex items-center space-x-3 space-x-reverse">
          <div className="flex items-center bg-white rounded-lg border border-gray-300 shadow-sm py-2 px-4">
            <Clock8 className="h-5 w-5 text-gray-400 ml-2" />
            <div>
              <p className="text-sm text-gray-500 leading-none">סה"כ חלוקות</p>
              <p className="text-lg font-semibold text-gray-800">{statistics.totalForms}</p>
            </div>
          </div>
        </div>
      </div>

      {batches.map((batch) => (
        <div key={batch.id} className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          <div 
            className="px-6 py-4 cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors flex justify-between items-center"
            onClick={() => toggleBatch(batch.id)}
          >
            <div className="flex items-center space-x-4 space-x-reverse">
              <Package className="h-5 w-5 text-blue-500" />
              <div>
                <h3 className="text-lg font-medium text-gray-900">{batch.name}</h3>
                <p className="text-sm text-gray-500">
                  {new Date(batch.timestamp).toLocaleDateString('he-IL')} • {getBatchSummary(batch)}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              {expandedBatches[batch.id] ? 
                <ChevronUp className="h-5 w-5 text-gray-500" /> : 
                <ChevronDown className="h-5 w-5 text-gray-500" />
              }
            </div>
          </div>
          
          {expandedBatches[batch.id] && (
            <div className="px-6 py-4">
              <BatchStatistics batchStats={statistics.batchStats[batch.id]} />
              
              <div className="space-y-3 mt-4">
                {batch.forms.map((form) => (
                  <div 
                    key={form._id} 
                    className={`border rounded-lg ${form.status === 'completed' ? 'border-green-200 bg-green-50' : 
                      form.status === 'expired' ? 'border-red-200 bg-red-50' : 'border-blue-200 bg-blue-50'}`}
                  >
                    <div 
                      className="px-4 py-3 cursor-pointer flex justify-between items-center"
                      onClick={() => toggleForm(form._id)}
                    >
                      <div className="flex items-center space-x-4 space-x-reverse">
                        <Building className="h-5 w-5 text-gray-500" />
                        <div>
                          <h4 className="font-medium">{form.branchName}</h4>
                          <div className="flex items-center space-x-2 space-x-reverse text-sm text-gray-500">
                            <span>{getStatusBadge(form.status)}</span>
                            <span>•</span>
                            <span className="flex items-center">
                              <Calendar className="h-3.5 w-3.5 ml-1" />
                              {formatDate(form.createdAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex space-x-2 space-x-reverse">
                        <button 
                          className="p-1.5 rounded-full hover:bg-white transition-colors"
                          onClick={(e) => {
                            e.stopPropagation();
                            copyAccessLink(form.accessToken);
                          }}
                          title="העתק קישור"
                        >
                          <Link className="h-4 w-4 text-gray-500" />
                        </button>
                        {expandedForms[form._id] ? 
                          <ChevronUp className="h-5 w-5 text-gray-500" /> : 
                          <ChevronDown className="h-5 w-5 text-gray-500" />
                        }
                      </div>
                    </div>
                    
                    {expandedForms[form._id] && (
                      <div className="px-4 py-3 border-t border-gray-200 bg-white rounded-b-lg">
                        <div className="space-y-4">
                          <div>
                            <h5 className="text-sm font-medium text-gray-700 mb-1">מוצרים:</h5>
                            <div className="space-y-2">
                              {form.items.map((item, idx) => (
                                <div key={idx} className="p-2 bg-gray-50 rounded border border-gray-200">
                                  <div className="flex justify-between">
                                    <span className="text-sm font-medium">{item.productName}</span>
                                    {item.response && (
                                      <span className={`text-xs px-2 py-0.5 rounded-full ${
                                        item.response === 'approved' ? 'bg-green-100 text-green-800' : 
                                        item.response === 'rejected' ? 'bg-red-100 text-red-800' :
                                        'bg-yellow-100 text-yellow-800'
                                      }`}>
                                        {item.response === 'approved' ? 'מאושר' : 
                                         item.response === 'rejected' ? 'לא מאושר' :
                                         item.response === 'reduced' ? 'הופחת' : 'הוגדל'}
                                      </span>
                                    )}
                                  </div>
                                  <div className="flex flex-wrap text-xs text-gray-500 mt-1">
                                    <span className="ml-3">גורם אירוז: {item.packageFactor}</span>
                                    {item.barcode && <span className="ml-3">ברקוד: {item.barcode}</span>}
                                    {item.requestedAmount && <span className="ml-3">כמות: {item.requestedAmount}</span>}
                                    {item.notes && <span className="block w-full mt-1">הערות: {item.notes}</span>}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          
                          <div className="flex flex-wrap gap-3 text-xs text-gray-500">
                            <div className="bg-gray-100 px-3 py-1.5 rounded-full">
                              <Calendar className="h-3.5 w-3.5 inline ml-1" />
                              נוצר: {formatDate(form.createdAt)} {formatTime(form.createdAt)}
                            </div>
                            {form.expiresAt && (
                              <div className="bg-gray-100 px-3 py-1.5 rounded-full">
                                <Clock className="h-3.5 w-3.5 inline ml-1" />
                                מועד אחרון: {formatDate(form.expiresAt)} {formatTime(form.expiresAt)}
                              </div>
                            )}
                            {form.completedAt && (
                              <div className="bg-green-100 text-green-800 px-3 py-1.5 rounded-full">
                                <Check className="h-3.5 w-3.5 inline ml-1" />
                                הושלם: {formatDate(form.completedAt)} {formatTime(form.completedAt)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
} 