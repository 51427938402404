import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { getPublicOfficeSupplyItems, getPublicBranches, createPublicOfficeSupplyOrder } from '../../utils/api';
import { Box, ShoppingBag, CornerUpLeft, Package, Key, User, Home, PlusCircle, MinusCircle, CheckCircle } from 'lucide-react';

const PublicOfficeSuppliesOrder = () => {
  const [items, setItems] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [notes, setNotes] = useState('');
  const [requesterName, setRequesterName] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [formToken, setFormToken] = useState(localStorage.getItem('orderFormToken') || '');
  const [tokenSaved, setTokenSaved] = useState(!!localStorage.getItem('orderFormToken'));
  const [searchQuery, setSearchQuery] = useState('');
  const [isValidatingToken, setIsValidatingToken] = useState(false);
  const [requesterPhone, setRequesterPhone] = useState('');

  useEffect(() => {
    if (tokenSaved) {
      fetchBranches();
      fetchItems();
    }
  }, [tokenSaved]);

  const saveToken = () => {
    if (!formToken.trim()) {
      toast.error('יש להזין מפתח הזדהות');
      return;
    }
    
    setIsValidatingToken(true);
    validateToken();
  };

  const validateToken = async () => {
    try {
      await getPublicBranches(formToken);
      
      localStorage.setItem('orderFormToken', formToken);
      setTokenSaved(true);
      toast.success('מפתח הזדהות נשמר בהצלחה');
      
      fetchBranches();
      fetchItems();
    } catch (error) {
      console.error('Error validating token:', error);
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        toast.error('מפתח הזדהות אינו נכון');
      } else {
        toast.error('שגיאה באימות מפתח ההזדהות');
      }
    } finally {
      setIsValidatingToken(false);
    }
  };

  const clearToken = () => {
    localStorage.removeItem('orderFormToken');
    setFormToken('');
    setTokenSaved(false);
    toast.success('מפתח הזדהות נמחק');
  };

  const fetchBranches = async () => {
    try {
      const data = await getPublicBranches(formToken);
      setBranches(data);
    } catch (error) {
      console.error('Error fetching branches:', error);
      
      // Show appropriate error messages but don't automatically clear token
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        toast.error('מפתח הזדהות אינו נכון');
      } else {
        toast.error('שגיאה בטעינת רשימת הסניפים');
      }
    }
  };

  const fetchItems = async () => {
    try {
      const data = await getPublicOfficeSupplyItems(formToken);
      setItems(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching items:', error);
      
      // Show appropriate error messages but don't automatically clear token
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        toast.error('מפתח הזדהות אינו נכון');
      } else {
        toast.error('שגיאה בטעינת פריטים');
      }
      setLoading(false);
    }
  };

  const handleQuantityChange = (itemId, quantity) => {
    const numericQuantity = parseInt(quantity) || 0;
    
    if (numericQuantity < 0) return;
    if (numericQuantity > 1000) {
      toast.error('כמות מקסימלית להזמנה היא 1000');
      return;
    }

    setSelectedItems(prev => {
      const existing = prev.find(item => item.itemId === itemId);
      if (existing) {
        if (numericQuantity === 0) {
          return prev.filter(item => item.itemId !== itemId);
        }
        return prev.map(item => 
          item.itemId === itemId 
            ? { ...item, quantity: numericQuantity }
            : item
        );
      }
      if (numericQuantity > 0) {
        const item = items.find(i => i.itemId === itemId);
        return [...prev, {
          itemId: item.itemId,
          name: item.name,
          price: item.price,
          quantity: numericQuantity
        }];
      }
      return prev;
    });
  };

  // Quick function to increase/decrease quantity
  const adjustQuantity = (itemId, adjustment) => {
    const item = selectedItems.find(item => item.itemId === itemId);
    const currentQuantity = item ? item.quantity : 0;
    const newQuantity = Math.max(0, currentQuantity + adjustment);
    
    handleQuantityChange(itemId, newQuantity);
  };

  const calculateTotal = () => {
    return selectedItems.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0);
  };

  const validateForm = () => {
    if (selectedItems.length === 0) {
      toast.error('נא לבחור לפחות פריט אחד');
      return false;
    }
    
    if (!selectedBranch) {
      toast.error('נא לבחור סניף');
      return false;
    }
    
    if (!requesterName.trim()) {
      toast.error('נא להזין את שם המזמין');
      return false;
    }

    if (!requesterPhone.trim()) {
      toast.error('נא להזין מספר טלפון');
      return false;
    }
    
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setSubmitting(true);
    try {
      const orderData = {
        items: selectedItems,
        notes,
        totalAmount: calculateTotal(),
        branchId: selectedBranch,
        requesterName: requesterName.trim(),
        requesterPhone: requesterPhone.trim()
      };

      await createPublicOfficeSupplyOrder(orderData, formToken);
      
      toast.success('ההזמנה נשלחה בהצלחה');
      setSelectedItems([]);
      setNotes('');
      setRequesterName('');
      setRequesterPhone('');
    } catch (error) {
      console.error('Error submitting order:', error);
      
      // Handle authentication errors specifically
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        toast.error('מפתח הזדהות אינו נכון');
      } else {
        toast.error(error.response?.data?.message || 'שגיאה בשליחת ההזמנה');
      }
    } finally {
      setSubmitting(false);
    }
  };

  // Filter items based on search query
  const filteredItems = searchQuery 
    ? items.filter(item => 
        item.name.includes(searchQuery) || 
        item.itemId.includes(searchQuery)
      )
    : items;

  if (!tokenSaved) {
    return (
      <div className="max-w-xl mx-auto bg-white rounded-lg shadow-lg p-6 mt-10">
        {/* Notice Banner */}
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700 text-right">
                ניתן לבצע הזמנות עד 15 לחודש בכל חודש. ניתן לעשות הזמנה פעם אחת בחודש
              </p>
            </div>
          </div>
        </div>

        {/* Header */}
        <div className="text-center mb-8">
          <div className="bg-blue-600 text-white py-4 px-6 rounded-t-lg -mt-6 -mx-6 mb-6">
            <h1 className="text-2xl font-bold">טופס הזמנה לציוד משרדי</h1>
          </div>
          <div className="flex justify-center mb-4">
            <Key size={28} className="text-blue-600" />
          </div>
          <p className="text-gray-600 mb-6">יש להזין את מפתח ההזדהות לצורך גישה למערכת</p>
        </div>
        
        <div className="mb-6">
          <label className="block text-lg font-medium text-gray-700 mb-2 text-right">מפתח הזדהות</label>
          <div className="relative">
            <input
              type="password"
              value={formToken}
              onChange={(e) => setFormToken(e.target.value)}
              className="w-full px-4 py-3 border rounded-md text-right pr-10 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="הזן את מפתח ההזדהות"
              style={{ direction: 'rtl' }}
              disabled={isValidatingToken}
            />
            <Key size={18} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </div>
        
        <button
          onClick={saveToken}
          disabled={isValidatingToken}
          className={`w-full bg-blue-600 text-white py-3 px-4 rounded-md transition duration-200 flex items-center justify-center text-lg ${
            isValidatingToken ? 'opacity-75 cursor-not-allowed' : 'hover:bg-blue-700'
          }`}
        >
          {isValidatingToken ? (
            <>
              <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white ml-2"></span>
              <span>מאמת מפתח הזדהות...</span>
            </>
          ) : (
            <>
              <span>כניסה למערכת</span>
              <CornerUpLeft size={18} className="mr-2" />
            </>
          )}
        </button>
        
        {/* Footer */}
        <div className="mt-10 pt-6 border-t border-gray-200 text-center text-gray-500 text-sm">
          <p>פותח ע"י ישי אלמולי</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-lg p-6">
        {/* Notice Banner */}
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 -mx-6 -mt-6 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700 text-right">
                ניתן לבצע הזמנות עד 15 לחודש בכל חודש. ניתן לעשות הזמנה פעם אחת בחודש
              </p>
            </div>
          </div>
        </div>
        <div className="bg-blue-600 text-white py-4 px-6 rounded-t-lg -mt-6 -mx-6 mb-6">
          <h1 className="text-2xl font-bold text-center">טופס הזמנה לציוד משרדי</h1>
        </div>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
        </div>
        <div className="mt-10 pt-6 border-t border-gray-200 text-center text-gray-500 text-sm">
          <p>פותח ע"י ישי אלמולי</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-lg p-6 mb-10">
      {/* Notice Banner */}
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 -mx-6 -mt-6 mb-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700 text-right">
              ניתן לבצע הזמנות עד 15 לחודש בכל חודש. ניתן לעשות הזמנה פעם אחת בחודש
            </p>
          </div>
        </div>
      </div>

      {/* Header */}
      <div className="bg-blue-600 text-white py-4 px-6 rounded-t-lg -mt-6 -mx-6 mb-6">
        <div className="flex justify-between items-center">
          <button 
            onClick={clearToken}
            className="text-sm text-white hover:text-blue-100 transition flex items-center"
          >
            <Key size={14} className="ml-1" />
            החלף מפתח הזדהות
          </button>
          <h1 className="text-2xl font-bold">טופס הזמנה לציוד משרדי</h1>
        </div>
      </div>
      
      <form onSubmit={handleSubmit}>
        {/* User & Branch Selection Section */}
        <div className="bg-blue-50 p-4 rounded-lg mb-8">
          <h2 className="text-lg font-semibold text-blue-800 mb-4 text-right">פרטי המזמין</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2 text-right">סניף</label>
              <div className="relative">
                <select
                  value={selectedBranch}
                  onChange={(e) => setSelectedBranch(e.target.value)}
                  className="w-full px-4 py-3 border rounded-md appearance-none text-right pr-10 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                  style={{ direction: 'rtl' }}
                >
                  <option value="">בחר סניף</option>
                  {branches.map(branch => (
                    <option key={branch._id} value={branch._id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
                <Home size={18} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
              </div>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2 text-right">שם המזמין</label>
              <div className="relative">
                <input
                  type="text"
                  value={requesterName}
                  onChange={(e) => setRequesterName(e.target.value)}
                  className="w-full px-4 py-3 border rounded-md text-right pr-10 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="הזן את שמך המלא"
                  required
                  style={{ direction: 'rtl' }}
                />
                <User size={18} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2 text-right">טלפון המזמין</label>
              <div className="relative">
                <input
                  type="tel"
                  value={requesterPhone}
                  onChange={(e) => setRequesterPhone(e.target.value)}
                  className="w-full px-4 py-3 border rounded-md text-right pr-10 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="הזן את מספר הטלפון"
                  required
                  style={{ direction: 'rtl' }}
                />
                <svg xmlns="http://www.w3.org/2000/svg" className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* Search Bar */}
        <div className="mb-6">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-3 border rounded-md text-right pr-10 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="חיפוש פריטים לפי שם או מק״ט..."
              style={{ direction: 'rtl' }}
            />
            <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400">🔍</span>
          </div>
        </div>

        {/* Selected Items Summary */}
        {selectedItems.length > 0 && (
          <div className="bg-green-50 p-4 rounded-lg mb-6 border border-green-200">
            <div className="flex justify-between items-center mb-2">
              <span className="text-green-700 text-lg font-semibold">סה"כ פריטים שנבחרו: {selectedItems.length}</span>
              <span className="text-green-700 text-lg font-semibold">סה"כ להזמנה: ₪{calculateTotal().toFixed(2)}</span>
            </div>
          </div>
        )}
          
        {/* Items Table */}
        <div className="overflow-x-auto bg-white rounded-lg border border-gray-200 mb-6">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מק"ט</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">שם פריט</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מחיר</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">כמות</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סה"כ</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredItems.map((item) => {
                const selectedItem = selectedItems.find(si => si.itemId === item.itemId);
                const quantity = selectedItem?.quantity || 0;
                const total = quantity * item.price;

                return (
                  <tr key={item.itemId} className={quantity > 0 ? 'bg-blue-50' : ''}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.itemId}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">₪{item.price.toFixed(2)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2 space-x-reverse">
                        <button 
                          type="button"
                          onClick={() => adjustQuantity(item.itemId, -1)}
                          className="p-1 text-red-600 hover:bg-red-100 rounded-full"
                          disabled={quantity === 0}
                        >
                          <MinusCircle size={20} />
                        </button>
                        
                        <input
                          type="number"
                          min="0"
                          max="1000"
                          value={quantity || ''}
                          onChange={(e) => handleQuantityChange(item.itemId, e.target.value)}
                          className="w-16 px-2 py-1 border rounded-md text-center"
                        />
                        
                        <button 
                          type="button"
                          onClick={() => adjustQuantity(item.itemId, 1)}
                          className="p-1 text-green-600 hover:bg-green-100 rounded-full"
                        >
                          <PlusCircle size={20} />
                        </button>
                        
                        {/* Quick-add buttons for mobile */}
                        <div className="md:hidden flex space-x-1 space-x-reverse">
                          {[1, 5, 10].map(num => (
                            <button
                              key={num}
                              type="button"
                              onClick={() => handleQuantityChange(item.itemId, num)}
                              className="px-2 py-1 text-xs bg-blue-100 text-blue-700 rounded hover:bg-blue-200"
                            >
                              {num}
                            </button>
                          ))}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {total > 0 && `₪${total.toFixed(2)}`}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          
          {/* Empty state when no items match the filter */}
          {filteredItems.length === 0 && (
            <div className="py-8 text-center">
              <Package size={40} className="mx-auto mb-4 text-gray-400" />
              <p className="text-gray-500">לא נמצאו פריטים מתאימים לחיפוש</p>
            </div>
          )}
        </div>

        {/* Notes section */}
        <div className="mb-8">
          <label className="block text-sm font-medium text-gray-700 mb-2 text-right">הערות להזמנה</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full px-4 py-3 border rounded-md text-right focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows="3"
            placeholder="הערות נוספות להזמנה..."
            style={{ direction: 'rtl' }}
          ></textarea>
        </div>

        {/* Order submission */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={submitting || selectedItems.length === 0}
            className={`px-8 py-3 rounded-md text-white font-medium flex items-center ${
              submitting || selectedItems.length === 0
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {submitting ? (
              <>
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white ml-2"></span>
                שולח...
              </>
            ) : (
              <>
                <ShoppingBag size={18} className="ml-2" />
                שלח הזמנה
              </>
            )}
          </button>
        </div>
      </form>
      
      {/* Fixed total bar for mobile */}
      {selectedItems.length > 0 && (
        <div className="md:hidden fixed bottom-0 left-0 right-0 bg-blue-600 text-white py-3 px-4 flex justify-between items-center">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={submitting}
            className="px-4 py-2 bg-white text-blue-600 rounded-md font-medium"
          >
            {submitting ? 'שולח...' : 'שלח הזמנה'}
          </button>
          <div>
            <div className="text-sm">פריטים: {selectedItems.length}</div>
            <div className="font-bold">₪{calculateTotal().toFixed(2)}</div>
          </div>
        </div>
      )}
      
      {/* Footer */}
      <div className="mt-10 pt-6 border-t border-gray-200 text-center text-gray-500 text-sm">
        <p>פותח ע"י ישי אלמולי</p>
      </div>
    </div>
  );
};

export default PublicOfficeSuppliesOrder;