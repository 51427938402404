import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ShareIcon, ChartBarIcon, ArrowDownTrayIcon, CalendarIcon, PlusIcon, PencilIcon, EnvelopeIcon, ClipboardDocumentIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import { getBranchInventoryForms, exportQuantityReport } from '../../utils/api';
import { API_URL } from '../../config';
import api from '../../utils/api';

const InventoryFormList = () => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedAreaManager, setSelectedAreaManager] = useState('');
  const [branches, setBranches] = useState([]);
  const [areaManagers, setAreaManagers] = useState([]);
  const [activeTab, setActiveTab] = useState('active');
  const [paginationState, setPaginationState] = useState({
    active: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      itemsPerPage: 50,
      hasNextPage: false,
      hasPrevPage: false
    },
    completed: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      itemsPerPage: 50,
      hasNextPage: false,
      hasPrevPage: false
    },
    expired: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      itemsPerPage: 50,
      hasNextPage: false,
      hasPrevPage: false
    }
  });
  const [exportDates, setExportDates] = useState({
    startDate: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });
  const [showExportModal, setShowExportModal] = useState(false);
  const [showExpirationModal, setShowExpirationModal] = useState(false);
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [editedQuestions, setEditedQuestions] = useState([]);
  const [newTimeLimit, setNewTimeLimit] = useState(24);
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const [branchesLoading, setBranchesLoading] = useState(true);
  const [formsLoading, setFormsLoading] = useState(false);
  const [expandedFormId, setExpandedFormId] = useState(null);

  // Add initial debug logging
  useEffect(() => {
    console.log('Initial component mount - User data:', {
      user,
      isBranchUser: user?.role === 'branch',
      hasBranches: Boolean(user?.branches),
      branchesArray: user?.branches
    });
  }, [user]);

  // Check if user is branch role
  const isAdminOrManager = user?.role === 'admin' || user?.role === 'manager';

  // Filter forms based on active tab
  const filteredForms = useMemo(() => {
    if (!forms || forms.length === 0) return [];
    
    // Actually filter the forms based on the active tab
    if (activeTab === 'active') {
      return forms.filter(form => form.status === 'active' || form.status === 'pending' || form.status === 'in_progress');
    } else if (activeTab === 'completed') {
      return forms.filter(form => form.status === 'completed');
    } else if (activeTab === 'expired') {
      return forms.filter(form => form.status === 'expired');
    }
    
    return forms;
  }, [forms, activeTab]);

  // Status translation helper
  const getStatusInHebrew = (status) => {
    const statusMap = {
      'active': 'פעיל',
      'completed': 'הושלם',
      'expired': 'פג תוקף',
      'pending': 'ממתין',
      'in_progress': 'בתהליך'
    };
    return statusMap[status] || status;
  };

  // Add useEffect for fetching area managers
  useEffect(() => {
    const fetchAreaManagers = async () => {
      try {
        const response = await fetch(`${API_URL}/api/inventory/area-managers`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch area managers');
        const data = await response.json();
        setAreaManagers(data);
      } catch (err) {
        console.error('Error fetching area managers:', err);
      }
    };

    if (isAdminOrManager) {
      fetchAreaManagers();
    }
  }, [isAdminOrManager]);

  // Extract the current pagination page based on active tab to avoid complex expression in dependency array
  const currentTabPage = useMemo(() => {
    if (!paginationState || !activeTab) return 1;
    return paginationState[activeTab]?.currentPage || 1;
  }, [paginationState, activeTab]);

  // Modify the fetchForms function to include pagination and status
  useEffect(() => {
    const fetchForms = async () => {
      if (!branches.length) return;
      
      try {
        console.log('Fetching forms, selected branch:', selectedBranch);
        console.log('Selected area manager:', selectedAreaManager);
        setFormsLoading(true);
        setLoading(true); // Set main loading state to true as well
        
        if (user?.role === 'branch') {
          if (!user.branches || !Array.isArray(user.branches) || user.branches.length === 0) {
            console.error('Branch user has no branches assigned:', user);
            setError('לא נמצאו סניפים משויכים למשתמש שלך');
            setForms([]);
            return;
          }
          
          const branchPromises = user.branches.map(branchId => getBranchInventoryForms(branchId));
          const responses = await Promise.all(branchPromises);
          console.log('Branch user responses from all branches:', responses);
          
          const allForms = responses.flatMap(response => response.forms || []);
          console.log('Combined forms from all branches:', allForms);
          setForms(allForms);
        } else {
          // For admin/manager, fetch forms with status and pagination
          const currentPage = paginationState[activeTab]?.currentPage || 1;
          const itemsPerPage = paginationState[activeTab]?.itemsPerPage || 50;
          
          console.log('Fetching forms with params:', {
            selectedBranch,
            selectedAreaManager,
            activeTab,
            currentPage,
            itemsPerPage
          });
          
          const response = await getBranchInventoryForms(
            selectedBranch,
            selectedAreaManager,
            activeTab,
            currentPage,
            itemsPerPage
          );
          
          console.log('Received response:', response);
          
          if (response) {
            // Set forms from the response
            setForms(response.forms || []);
            
            // Update pagination if provided
            if (response.pagination) {
              setPaginationState(prev => ({
                ...prev,
                [activeTab]: response.pagination
              }));
            }
          }
        }
      } catch (error) {
        console.error('Error fetching forms:', error);
        toast.error('שגיאה בטעינת הטפסים');
      } finally {
        setFormsLoading(false);
        setLoading(false); // Set main loading state to false
      }
    };

    if (activeTab && branches.length > 0) {
      fetchForms();
    }
  }, [selectedBranch, selectedAreaManager, branches, user, activeTab, currentTabPage]);

  // For branch users, automatically select their branch if they only have one
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        setBranchesLoading(true);
        const response = await api.get('/api/inventory/branches');
        let fetchedBranches = response.data;
        
        // Filter branches for branch users and area managers
        if ((user?.role === 'branch' || user?.role === 'area_manager') && user?.branches?.length > 0) {
          fetchedBranches = fetchedBranches.filter(branch => 
            user.branches.some(userBranch => 
              userBranch.toString() === branch._id.toString()
            )
          );
          
          // Auto-select branch if user has only one
          if (fetchedBranches.length === 1) {
            setSelectedBranch(fetchedBranches[0]._id);
          }
        }
        
        console.log('Fetched branches:', fetchedBranches);
        setBranches(fetchedBranches);
      } catch (error) {
        console.error('Error fetching branches:', error);
        toast.error('שגיאה בטעינת הסניפים');
      } finally {
        setBranchesLoading(false);
      }
    };

    // Only fetch if we have user data
    if (user) {
      fetchBranches();
    }
  }, [user]);

  // Add a new useEffect to fetch counts for all tabs on initial load
  useEffect(() => {
    const fetchAllTabCounts = async () => {
      if (!branches.length) return;
      
      try {
        // Fetch counts for all tabs
        const tabTypes = ['active', 'completed', 'expired'];
        
        if (user?.role === 'branch') {
          // For branch users, we need to count locally from their forms
          if (!user.branches || !Array.isArray(user.branches) || user.branches.length === 0) {
            console.error('Branch user has no branches assigned:', user);
            return;
          }
          
          // Fetch all forms for the branch user's branches
          const branchPromises = user.branches.map(branchId => getBranchInventoryForms(branchId));
          const responses = await Promise.all(branchPromises);
          const allForms = responses.flatMap(response => response.forms || []);
          
          // Count forms by status
          const activeForms = allForms.filter(form => 
            form.status === 'active' || form.status === 'pending' || form.status === 'in_progress'
          ).length;
          
          const completedForms = allForms.filter(form => form.status === 'completed').length;
          const expiredForms = allForms.filter(form => form.status === 'expired').length;
          
          // Update pagination state with counts
          setPaginationState(prev => ({
            active: {
              ...prev.active,
              totalItems: activeForms,
              totalPages: Math.ceil(activeForms / prev.active.itemsPerPage)
            },
            completed: {
              ...prev.completed,
              totalItems: completedForms,
              totalPages: Math.ceil(completedForms / prev.completed.itemsPerPage)
            },
            expired: {
              ...prev.expired,
              totalItems: expiredForms,
              totalPages: Math.ceil(expiredForms / prev.expired.itemsPerPage)
            }
          }));
        } else {
          // For admin/manager users, use the API endpoint
          const countPromises = tabTypes.map(tabType => 
            fetch(`${API_URL}/api/inventory/forms/count?status=${tabType}&branch=${selectedBranch || ''}&areaManager=${selectedAreaManager || ''}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            }).then(res => res.json())
          );
          
          const results = await Promise.all(countPromises);
          
          // Update pagination state with counts
          setPaginationState(prev => ({
            active: {
              ...prev.active,
              totalItems: results[0].count || 0,
              totalPages: Math.ceil((results[0].count || 0) / prev.active.itemsPerPage)
            },
            completed: {
              ...prev.completed,
              totalItems: results[1].count || 0,
              totalPages: Math.ceil((results[1].count || 0) / prev.completed.itemsPerPage)
            },
            expired: {
              ...prev.expired,
              totalItems: results[2].count || 0,
              totalPages: Math.ceil((results[2].count || 0) / prev.expired.itemsPerPage)
            }
          }));
        }
      } catch (error) {
        console.error('Error fetching tab counts:', error);
      }
    };
    
    if (branches.length > 0) {
      fetchAllTabCounts();
    }
  }, [branches, selectedBranch, selectedAreaManager, user]);

  // Calculate stats for the current tab
  const tabStats = useMemo(() => {
    if (!filteredForms.length) return null;

    const totalForms = filteredForms.length;
    const totalResponses = filteredForms.reduce((sum, form) => {
      if (!form.responses) return sum;
      return sum + form.responses.reduce((total, response) => 
        total + (response.answers?.length || 0), 0);
    }, 0);
    
    const overdueForms = forms.filter(form => {
      const isExpired = new Date(form.expiresAt) < new Date();
      return (form.status !== 'completed' || !form.responses || form.responses.length === 0) && isExpired;
    }).length;

    return {
      totalForms,
      totalResponses,
      overdueForms
    };
  }, [filteredForms, forms]);

  // Add updateFormExpiration function
  const updateFormExpiration = async (formId, timeLimit) => {
    try {
      const response = await fetch(`${API_URL}/api/inventory/${formId}/expiration`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ timeLimit })
      });

      if (!response.ok) {
        throw new Error('Failed to update expiration time');
      }

      // Refresh forms after update
      if (user?.role === 'branch') {
        if (!user.branches || !Array.isArray(user.branches) || user.branches.length === 0) {
          console.error('Branch user has no branches assigned:', user);
          return;
        }
        
        const branchPromises = user.branches.map(branchId => getBranchInventoryForms(branchId));
        const responses = await Promise.all(branchPromises);
        const allForms = responses.flatMap(response => response.forms || []);
        setForms(allForms);
      } else {
        // For admin/manager, fetch forms with status and pagination
        const currentPage = paginationState[activeTab]?.currentPage || 1;
        const itemsPerPage = paginationState[activeTab]?.itemsPerPage || 50;
        
        const formsResponse = await getBranchInventoryForms(
          selectedBranch,
          selectedAreaManager,
          activeTab,
          currentPage,
          itemsPerPage
        );
        
        if (formsResponse) {
          setForms(formsResponse.forms || []);
          
          if (formsResponse.pagination) {
            setPaginationState(prev => ({
              ...prev,
              [activeTab]: formsResponse.pagination
            }));
          }
        }
      }

      toast.success('זמן הטופס עודכן בהצלחה');
      setShowExpirationModal(false);
    } catch (error) {
      console.error('Error updating form expiration:', error);
      toast.error('שגיאה בעדכון זמן הטופס');
    }
  };

  // Add handleEditExpiration function
  const handleEditExpiration = (form) => {
    setSelectedForm(form);
    // Calculate remaining hours and set as default
    const remainingHours = Math.max(1, Math.ceil((new Date(form.expiresAt) - new Date()) / (1000 * 60 * 60)));
    setNewTimeLimit(remainingHours);
    setShowExpirationModal(true);
  };

  // Add updateFormQuestions function
  const updateFormQuestions = async (formId, questions) => {
    try {
      const response = await fetch(`${API_URL}/api/inventory/${formId}/questions`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ questions })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to update questions');
      }

      // Refresh forms after update
      if (user?.role === 'branch') {
        if (!user.branches || !Array.isArray(user.branches) || user.branches.length === 0) {
          console.error('Branch user has no branches assigned:', user);
          return;
        }
        
        const branchPromises = user.branches.map(branchId => getBranchInventoryForms(branchId));
        const responses = await Promise.all(branchPromises);
        const allForms = responses.flatMap(response => response.forms || []);
        setForms(allForms);
      } else {
        // For admin/manager, fetch forms with status and pagination
        const currentPage = paginationState[activeTab]?.currentPage || 1;
        const itemsPerPage = paginationState[activeTab]?.itemsPerPage || 50;
        
        const formsResponse = await getBranchInventoryForms(
          selectedBranch,
          selectedAreaManager,
          activeTab,
          currentPage,
          itemsPerPage
        );
        
        if (formsResponse) {
          setForms(formsResponse.forms || []);
          
          if (formsResponse.pagination) {
            setPaginationState(prev => ({
              ...prev,
              [activeTab]: formsResponse.pagination
            }));
          }
        }
      }

      toast.success('השאלות עודכנו בהצלחה');
      setShowQuestionsModal(false);
    } catch (error) {
      console.error('Error updating questions:', error);
      toast.error(error.message || 'שגיאה בעדכון השאלות');
    }
  };

  // Add handleEditQuestions function
  const handleEditQuestions = (form) => {
    if (form.responses && form.responses.length > 0) {
      toast.error('לא ניתן לערוך טופס שכבר התקבלו עבורו תשובות');
      return;
    }
    setSelectedForm(form);
    setEditedQuestions([...form.questions]);
    setShowQuestionsModal(true);
  };

  // Add question management functions
  const addQuestion = () => {
    setEditedQuestions([
      ...editedQuestions,
      {
        questionText: '',
        questionType: 'text',
        required: false,
        options: [],
        order: editedQuestions.length + 1,
        branchingLogic: []
      }
    ]);
  };

  const updateQuestion = (index, field, value) => {
    const newQuestions = [...editedQuestions];
    newQuestions[index] = {
      ...newQuestions[index],
      [field]: value
    };
    
    // If changing to multiple choice, ensure options and branchingLogic are initialized
    if (field === 'questionType' && value === 'multipleChoice') {
      newQuestions[index].options = newQuestions[index].options || [];
      newQuestions[index].branchingLogic = newQuestions[index].branchingLogic || [];
    }
    
    setEditedQuestions(newQuestions);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = editedQuestions
      .filter((_, i) => i !== index)
      .map((question, newIndex) => ({
        ...question,
        order: newIndex + 1 // Update order for remaining questions
      }));
    setEditedQuestions(updatedQuestions);
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...editedQuestions];
    if (!newQuestions[questionIndex].options) {
      newQuestions[questionIndex].options = [];
    }
    if (!newQuestions[questionIndex].branchingLogic) {
      newQuestions[questionIndex].branchingLogic = [];
    }
    newQuestions[questionIndex].options.push('');
    newQuestions[questionIndex].branchingLogic.push({ nextQuestionText: '' });
    setEditedQuestions(newQuestions);
  };

  const updateOption = (questionIndex, optionIndex, value) => {
    const newQuestions = [...editedQuestions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setEditedQuestions(newQuestions);
  };

  const removeOption = (questionIndex, optionIndex) => {
    const newQuestions = [...editedQuestions];
    newQuestions[questionIndex].options = newQuestions[questionIndex].options.filter((_, i) => i !== optionIndex);
    if (newQuestions[questionIndex].branchingLogic) {
      newQuestions[questionIndex].branchingLogic = newQuestions[questionIndex].branchingLogic.filter((_, i) => i !== optionIndex);
    }
    setEditedQuestions(newQuestions);
  };

  // Add handleBranchingLogicChange function
  const handleBranchingLogicChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...editedQuestions];
    if (!newQuestions[questionIndex].branchingLogic) {
      newQuestions[questionIndex].branchingLogic = [];
    }
    if (!newQuestions[questionIndex].branchingLogic[optionIndex]) {
      newQuestions[questionIndex].branchingLogic[optionIndex] = {};
    }
    newQuestions[questionIndex].branchingLogic[optionIndex].nextQuestionText = value;
    setEditedQuestions(newQuestions);
  };

  // Add function to handle row click
  const handleRowClick = (formId) => {
    setExpandedFormId(expandedFormId === formId ? null : formId);
  };

  const handleEmailMessage = (form) => {
    console.log('Form branch data:', form.branch);
    
    if (!form.branch?.email) {
      console.log('Missing email for branch:', form.branch);
      toast.error('לא נמצאה כתובת מייל לסניף זה');
      return;
    }

    const formUrl = `${window.location.origin}/inventory/form/${form.accessToken}`;
    const branchName = form.branch?.name || 'סניף לא ידוע';
    const today = new Date().toLocaleDateString('he-IL');

    try {
      // Create email content with the URL on a separate line
      const subject = encodeURIComponent(`שאלון מלאי - ${branchName} - ${today}`);
      const body = encodeURIComponent(`שלום,

נא למלא את שאלון המלאי בקישור הבא:

${formUrl}

לחץ על הקישור או העתק אותו לדפדן.

בברכה`);

      // Create mailto URL
      const mailtoUrl = `mailto:${encodeURIComponent(form.branch.email)}?subject=${subject}&body=${body}`;

      // Try to open default email client
      window.location.href = mailtoUrl;
      
      toast.success('נפתח חלון מייל חדש');
    } catch (error) {
      console.error('Error opening email client:', error);
      
      // Fallback: Copy to clipboard
      navigator.clipboard.writeText(`To: ${form.branch.email}
Subject: שאלון מלאי - ${branchName} - ${today}

${formUrl}`)
        .then(() => {
          toast.success('הטקסט הועתק ללוח - לא ניתן היה לפתוח את תוכנת המייל');
        })
        .catch(() => {
          toast.error('שגיאה בפתיחת המייל ובהעתקת הטקסט');
        });
    }
  };

  const handleCopyMessage = (form) => {
    const today = new Date();
    const hebrewDate = today.toLocaleDateString('he-IL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    const formUrl = `${window.location.origin}/inventory/form/${form.accessToken}`;
    const branchName = form.branch?.name || 'סניף לא ידוע';
    const subject = `שאלון אופטימזציה של המלאי - ${branchName} תאריך ${hebrewDate}`;
    const body = `הי,
נא לענות על השאלון המצורף:
${formUrl}

מטרת השאלון היא להבין את הסיבות לכך שפריטים מסוימים לא נמכרים.
בברכה,
ישי`;

    const fullMessage = `${subject}\n\n${body}`;

    navigator.clipboard.writeText(fullMessage)
      .then(() => {
        toast.success('הטקסט הועתק ללוח', {
          duration: 2000,
          position: 'bottom-center'
        });
      })
      .catch(() => {
        toast.error('שגיאה בהעתקת הטקסט', {
          duration: 2000,
          position: 'bottom-center'
        });
      });
  };

  // Add delete form function
  const handleDeleteForm = async (formId) => {
    if (!window.confirm('האם אתה בטוח שברצונך למחוק טופס זה?')) {
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/inventory/${formId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete form');
      }

      // Refresh forms after deletion
      if (user?.role === 'branch') {
        if (!user.branches || !Array.isArray(user.branches) || user.branches.length === 0) {
          console.error('Branch user has no branches assigned:', user);
          return;
        }
        
        const branchPromises = user.branches.map(branchId => getBranchInventoryForms(branchId));
        const responses = await Promise.all(branchPromises);
        const allForms = responses.flatMap(response => response.forms || []);
        setForms(allForms);
      } else {
        // For admin/manager, fetch forms with status and pagination
        const currentPage = paginationState[activeTab]?.currentPage || 1;
        const itemsPerPage = paginationState[activeTab]?.itemsPerPage || 50;
        
        const formsResponse = await getBranchInventoryForms(
          selectedBranch,
          selectedAreaManager,
          activeTab,
          currentPage,
          itemsPerPage
        );
        
        if (formsResponse) {
          setForms(formsResponse.forms || []);
          
          if (formsResponse.pagination) {
            setPaginationState(prev => ({
              ...prev,
              [activeTab]: formsResponse.pagination
            }));
          }
        }
      }
      
      toast.success('הטופס נמחק בהצלחה');
    } catch (error) {
      console.error('Error deleting form:', error);
      toast.error('שגיאה במחיקת הטופס');
    }
  };

  if (!user) {
    return (
      <div className="p-4">
        <p className="text-red-500">Error: User not authenticated</p>
      </div>
    );
  }

  const handleBranchChange = (event) => {
    const branch = event.target.value;
    console.log('Branch changed to:', branch);
    setSelectedBranch(branch);
  };

  const handleExportExcel = async () => {
    try {
      const response = await fetch(
        `${API_URL}/api/inventory/export-report?startDate=${exportDates.startDate}&endDate=${exportDates.endDate}`, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      
      if (!response.ok) throw new Error('Failed to generate report');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `דוח_טפסים_${new Date(exportDates.startDate).toLocaleDateString('he-IL')}_עד_${new Date(exportDates.endDate).toLocaleDateString('he-IL')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      
      toast.success('הדוח הורד בהצלחה');
      setShowExportModal(false);
    } catch (error) {
      console.error('Error exporting report:', error);
      toast.error('שגיאה בייצוא הדוח');
    }
  };

  const handleExportItemsReport = async () => {
    try {
      const response = await fetch(
        `${API_URL}/api/inventory/export-items-report?startDate=${exportDates.startDate}&endDate=${exportDates.endDate}`, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      
      if (!response.ok) throw new Error('Failed to generate items report');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `דוח_ניתוח_פריטים_${new Date(exportDates.startDate).toLocaleDateString('he-IL')}_עד_${new Date(exportDates.endDate).toLocaleDateString('he-IL')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      toast.success('דוח הפריטים הורד בהצלחה');
      setShowExportModal(false);
    } catch (error) {
      console.error('Error exporting items report:', error);
      toast.error('שגיאה בייצוא דוח הפריטים');
    }
  };

  const handleExportAreaManagerReport = async () => {
    try {
      const response = await fetch(
        `${API_URL}/api/inventory/export-area-manager-report?startDate=${exportDates.startDate}&endDate=${exportDates.endDate}`, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      
      if (!response.ok) throw new Error('Failed to generate area manager report');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `דוח_מנהלי_אזור_${new Date(exportDates.startDate).toLocaleDateString('he-IL')}_עד_${new Date(exportDates.endDate).toLocaleDateString('he-IL')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      toast.success('דוח מנהלי אזור הורד בהצלחה');
      setShowExportModal(false);
    } catch (error) {
      console.error('Error exporting area manager report:', error);
      toast.error('שגיאה בייצוא דוח מנהלי אזור');
    }
  };

  const handleExportQuantityReport = async () => {
    try {
      const blob = await exportQuantityReport(exportDates.startDate, exportDates.endDate);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `דוח_ניתוח_כמויות_${new Date(exportDates.startDate).toLocaleDateString('he-IL')}_עד_${new Date(exportDates.endDate).toLocaleDateString('he-IL')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      toast.success('דוח כמויות מלאי הורד בהצלחה');
      setShowExportModal(false);
    } catch (error) {
      console.error('Error exporting quantity report:', error);
      toast.error('שגיאה בייצוא דוח כמויות מלאי');
    }
  };

  // Modify tab change handler to reset pagination
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setPaginationState(prev => ({
      ...prev,
      [tab]: {
        ...prev[tab],
        currentPage: 1
      }
    }));
  };

  // Modify page change handler
  const handlePageChange = (newPage) => {
    setPaginationState(prev => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        currentPage: newPage
      }
    }));
  };

  return (
    <div className="p-6">
      {/* Tabs */}
      <div className="mb-6 border-b border-gray-200">
        <div className="flex gap-4">
          <button
            onClick={() => handleTabChange('active')}
            className={`py-3 px-6 font-medium text-sm border-b-2 transition-colors ${
              activeTab === 'active'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            שאלונים פעילים
            {paginationState.active.totalItems > 0 && (
              <span className="mr-2 bg-blue-100 text-blue-600 px-2 py-1 rounded-full text-xs">
                {paginationState.active.totalItems}
              </span>
            )}
          </button>
          <button
            onClick={() => handleTabChange('completed')}
            className={`py-3 px-6 font-medium text-sm border-b-2 transition-colors ${
              activeTab === 'completed'
                ? 'border-green-500 text-green-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            שאלונים שהושלמו
            {paginationState.completed.totalItems > 0 && (
              <span className="mr-2 bg-green-100 text-green-600 px-2 py-1 rounded-full text-xs">
                {paginationState.completed.totalItems}
              </span>
            )}
          </button>
          <button
            onClick={() => handleTabChange('expired')}
            className={`py-3 px-6 font-medium text-sm border-b-2 transition-colors ${
              activeTab === 'expired'
                ? 'border-red-500 text-red-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            שאלונים שפג תוקפם
            {paginationState.expired.totalItems > 0 && (
              <span className="mr-2 bg-red-100 text-red-600 px-2 py-1 rounded-full text-xs">
                {paginationState.expired.totalItems}
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Tab Stats */}
      {tabStats && (
        <div className="mb-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
              <p className="text-sm text-gray-600 mb-1">סה"כ שאלונים</p>
              <p className="text-2xl font-bold text-blue-600">{tabStats.totalForms}</p>
            </div>
            {activeTab === 'active' ? (
              <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
                <p className="text-sm text-gray-600 mb-1">באיחור</p>
                <p className="text-2xl font-bold text-red-600">{tabStats.overdueForms}</p>
              </div>
            ) : (
              <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
                <p className="text-sm text-gray-600 mb-1">סה"כ תגובות</p>
                <p className="text-2xl font-bold text-green-600">{tabStats.totalResponses}</p>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">
              {activeTab === 'completed' ? 'שאלונים שהושלמו' : 
               activeTab === 'expired' ? 'שאלונים שפג תוקפם' :
               'שאלונים פעילים'}
            </h1>
            <div className="flex gap-4">
              {/* Only show filters for admin/manager */}
              {isAdminOrManager && (
                <>
                  <select
                    value={selectedAreaManager}
                    onChange={(e) => setSelectedAreaManager(e.target.value)}
                    className="block w-64 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    disabled={branchesLoading}
                  >
                    <option value="">כל מנהלי האזור</option>
                    {areaManagers.map((manager) => (
                      <option key={manager._id} value={manager._id}>
                        {manager.firstName}
                      </option>
                    ))}
                  </select>

                  <select
                    value={selectedBranch}
                    onChange={handleBranchChange}
                    className="block w-64 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    disabled={branchesLoading}
                  >
                    <option value="">כל הסניפים</option>
                    {branches.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
              
              {/* Only show export and create buttons for admin/manager */}
              {isAdminOrManager && (
                <>
                  <button
                    onClick={() => navigate('/dashboard/inventory/forms/new')}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    <PlusIcon className="h-5 w-5 ml-2" />
                    צור שאלון חדש
                  </button>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setShowExportModal(true)}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <ArrowDownTrayIcon className="h-5 w-5 ml-2" />
                      ייצא לאקסל
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          {branchesLoading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
            </div>
          ) : error ? (
            <div className="p-4 bg-red-50 border-r-4 border-red-500 text-red-700 rounded">
              <p>{error}</p>
            </div>
          ) : (
            <>
              {formsLoading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                </div>
              ) : (
                <>
                  {filteredForms.length > 0 ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              כותרת
                            </th>
                            {/* Show branch column for all users */}
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              סניף
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              סטטוס
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              זמן להשלמה
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              שאלות
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              תגובות
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                              פעולות
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {filteredForms.map((form) => (
                            <React.Fragment key={form._id}>
                              <tr 
                                className="hover:bg-gray-50 cursor-pointer"
                                onClick={() => handleRowClick(form._id)}
                              >
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">{form.title}</div>
                                  <div className="text-sm text-gray-500">{form.description}</div>
                                </td>
                                {/* Show branch column for all users */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">{form.branch?.name || 'סניף לא ידוע'}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                    form.status === 'completed' 
                                      ? 'bg-green-100 text-green-800'
                                      : form.status === 'expired'
                                      ? 'bg-red-100 text-red-800'
                                      : 'bg-blue-100 text-blue-800'
                                  }`}>
                                    {getStatusInHebrew(form.status)}
                                  </span>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {new Date(form.expiresAt).toLocaleString('he-IL', {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                  })}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {form.questions?.length || 0}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                  {form.responses?.reduce((total, response) => 
                                    total + (response.answers?.length || 0), 0) || 0}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-2">
                                  {isAdminOrManager && (
                                    <div className="flex space-x-2 items-center">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleEmailMessage(form);
                                        }}
                                        className="flex items-center px-3 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 gap-2"
                                        title="שלח אימייל"
                                      >
                                        <EnvelopeIcon className="h-5 w-5" />
                                        <span className="text-sm font-medium">שלח מייל</span>
                                      </button>
                                      <div className="flex space-x-2">
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopyMessage(form);
                                          }}
                                          className="text-gray-600 hover:text-gray-900"
                                          title="העתק הודעה"
                                        >
                                          <ClipboardDocumentIcon className="h-5 w-5" />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const publicUrl = `${window.location.origin}/inventory/form/${form.accessToken}`;
                                            navigator.clipboard.writeText(publicUrl);
                                            toast.success('הקישור הועתק ללוח');
                                          }}
                                          className="text-gray-600 hover:text-gray-900"
                                          title="העתק קישור"
                                        >
                                          <ShareIcon className="h-5 w-5" />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/dashboard/inventory/forms/${form._id}/stats`);
                                          }}
                                          className="text-gray-600 hover:text-gray-900"
                                          title="סטטיסטיקות"
                                        >
                                          <ChartBarIcon className="h-5 w-5" />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditExpiration(form);
                                          }}
                                          className="text-gray-600 hover:text-gray-900"
                                          title="ערוך זמן"
                                        >
                                          <CalendarIcon className="h-5 w-5" />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditQuestions(form);
                                          }}
                                          className="text-gray-600 hover:text-gray-900"
                                          title="ערוך שאלות"
                                        >
                                          <PencilIcon className="h-5 w-5" />
                                        </button>
                                        <button
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteForm(form._id);
                                          }}
                                          className="text-red-600 hover:text-red-900"
                                          title="מחק טופס"
                                        >
                                          <TrashIcon className="h-5 w-5" />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </tr>
                              {expandedFormId === form._id && form.responses && form.responses.length > 0 && (
                                <tr>
                                  <td colSpan="7" className="px-3 lg:px-6 py-4 bg-gray-50">
                                    <div className="space-y-4">
                                      <h4 className="font-medium text-lg mb-2">תשובות לטופס</h4>
                                      {form.responses.map((response, responseIndex) => (
                                        <div key={responseIndex} className="bg-white p-4 rounded-lg shadow-sm">
                                          <div className="space-y-3">
                                            {response.answers.map((answer, answerIndex) => {
                                              const question = form.questions.find(q => q._id === answer.questionId);
                                              return (
                                                <div key={answerIndex} className="border-b pb-2">
                                                  <div className="font-medium text-gray-700">
                                                    {question?.questionText || 'שאלה לא ידועה'}
                                                  </div>
                                                  <div className="mt-1">
                                                    {answer.imageUrls && answer.imageUrls.length > 0 ? (
                                                      <div className="flex flex-wrap gap-2 mt-2">
                                                        {answer.imageUrls.map((url, imgIndex) => (
                                                          <a 
                                                            key={imgIndex}
                                                            href={url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="block"
                                                          >
                                                            <img 
                                                              src={url} 
                                                              alt={`תמונה ${imgIndex + 1}`}
                                                              className="h-20 w-20 object-cover rounded-lg"
                                                            />
                                                          </a>
                                                        ))}
                                                      </div>
                                                    ) : (
                                                      <div className="text-gray-900">{answer.answer}</div>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="text-center mt-8">
                      <p className="text-gray-500">
                        {activeTab === 'completed' ? 'לא נמצאו שאלונים שהושלמו.' : 
                         activeTab === 'expired' ? 'לא נמצאו שאלונים שפג תוקפם.' :
                         'לא נמצאו שאלונים שממתינים להשלמה.'}
                      </p>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* Export Modal */}
      {showExportModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative" dir="rtl">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">ייצוא דוחות</h3>
              <button
                onClick={() => setShowExportModal(false)}
                className="text-gray-500 hover:text-gray-700 text-xl"
              >
                ×
              </button>
            </div>
            
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  מתאריך
                </label>
                <input
                  type="date"
                  value={exportDates.startDate}
                  onChange={(e) => setExportDates(prev => ({ ...prev, startDate: e.target.value }))}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  עד תאריך
                </label>
                <input
                  type="date"
                  value={exportDates.endDate}
                  onChange={(e) => setExportDates(prev => ({ ...prev, endDate: e.target.value }))}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="flex flex-col gap-3 mt-8">
                <button
                  onClick={handleExportExcel}
                  className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <ArrowDownTrayIcon className="h-5 w-5 ml-2 inline-block" />
                  ייצא דוח טפסים מלא
                </button>
                
                <button
                  onClick={handleExportItemsReport}
                  className="w-full px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <ArrowDownTrayIcon className="h-5 w-5 ml-2 inline-block" />
                  ייצא דוח פריטים ותשובות
                </button>

                <button
                  onClick={handleExportQuantityReport}
                  className="w-full px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                >
                  <ArrowDownTrayIcon className="h-5 w-5 ml-2 inline-block" />
                  ייצא דוח ניתוח כמויות מלאי
                </button>

                <button
                  onClick={handleExportAreaManagerReport}
                  className="w-full px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <ArrowDownTrayIcon className="h-5 w-5 ml-2 inline-block" />
                  ייצא דוח מנהלי אזור
                </button>

                <button
                  onClick={() => setShowExportModal(false)}
                  className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  ביטול
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Expiration Modal */}
      {showExpirationModal && selectedForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative" dir="rtl">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">עדכון זמן טופס</h3>
              <button
                onClick={() => setShowExpirationModal(false)}
                className="text-gray-500 hover:text-gray-700 text-xl"
              >
                ×
              </button>
            </div>
            
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  זמן להשלמה (שעות)
                </label>
                <input
                  type="number"
                  min="1"
                  max="72"
                  value={newTimeLimit}
                  onChange={(e) => setNewTimeLimit(Number(e.target.value))}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <p className="text-sm text-gray-500 mt-1">יש להזין מספר שעות בין 1 ל-72</p>
              </div>

              <div className="flex justify-end gap-3 mt-8">
                <button
                  onClick={() => setShowExpirationModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  ביטול
                </button>
                <button
                  onClick={() => updateFormExpiration(selectedForm._id, newTimeLimit)}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  עדכן זמן
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Questions Modal */}
      {showQuestionsModal && selectedForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full mx-4 relative" dir="rtl" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">עריכת שאלות</h3>
              <button
                onClick={() => setShowQuestionsModal(false)}
                className="text-gray-500 hover:text-gray-700 text-xl"
              >
                ×
              </button>
            </div>
            
            <div className="space-y-6">
              {editedQuestions.map((question, questionIndex) => (
                <div key={questionIndex} className="border border-gray-200 rounded-lg p-4">
                  <div className="flex justify-between items-start mb-4">
                    <h4 className="text-lg font-medium">שאלה {questionIndex + 1}</h4>
                    <button
                      onClick={() => removeQuestion(questionIndex)}
                      className="text-red-600 hover:text-red-700"
                    >
                      מחק שאלה
                    </button>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        טקסט השאלה
                      </label>
                      <textarea
                        value={question.questionText}
                        onChange={(e) => updateQuestion(questionIndex, 'questionText', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        rows="2"
                      />
                    </div>

                    <div className="flex gap-4">
                      <div className="flex-1">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          סוג שאלה
                        </label>
                        <select
                          value={question.questionType}
                          onChange={(e) => updateQuestion(questionIndex, 'questionType', e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-md"
                        >
                          <option value="text">טקסט</option>
                          <option value="number">מספר</option>
                          <option value="boolean">כן/לא</option>
                          <option value="multipleChoice">בחירה מרובה</option>
                        </select>
                      </div>

                      <div className="flex items-end">
                        <label className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            checked={question.required}
                            onChange={(e) => updateQuestion(questionIndex, 'required', e.target.checked)}
                            className="w-4 h-4"
                          />
                          <span className="text-sm font-medium text-gray-700">שאלת חובה</span>
                        </label>
                      </div>
                    </div>

                    {question.questionType === 'multipleChoice' && (
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          אפשרויות בחירה
                        </label>
                        <div className="space-y-2">
                          {question.options?.map((option, optionIndex) => (
                            <div key={optionIndex} className="flex flex-col gap-2">
                              <div className="flex gap-2">
                                <input
                                  type="text"
                                  value={option}
                                  onChange={(e) => updateOption(questionIndex, optionIndex, e.target.value)}
                                  className="flex-1 p-2 border border-gray-300 rounded-md"
                                  placeholder={`אפשרות ${optionIndex + 1}`}
                                />
                                <button
                                  onClick={() => removeOption(questionIndex, optionIndex)}
                                  className="text-red-600 hover:text-red-700"
                                >
                                  מחק
                                </button>
                              </div>
                              
                              {/* Add Branching Logic Input */}
                              <div className="mr-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                  שאלת המשך (אופציונלי)
                                </label>
                                <input
                                  type="text"
                                  value={question.branchingLogic?.[optionIndex]?.nextQuestionText || ''}
                                  onChange={(e) => handleBranchingLogicChange(questionIndex, optionIndex, e.target.value)}
                                  placeholder="הזן שאלת המשך שתוצג כאשר נבחרת אפשרות זו..."
                                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                              </div>
                            </div>
                          ))}
                          <button
                            onClick={() => addOption(questionIndex)}
                            className="text-blue-600 hover:text-blue-700 text-sm"
                          >
                            + הוסף אפשרות
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

              <button
                onClick={addQuestion}
                className="w-full p-2 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:text-gray-800 hover:border-gray-400"
              >
                + הוסף שאלה חדשה
              </button>

              <div className="flex justify-end gap-3 mt-8">
                <button
                  onClick={() => setShowQuestionsModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  ביטול
                </button>
                <button
                  onClick={() => updateFormQuestions(selectedForm._id, editedQuestions)}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
                >
                  שמור שינויים
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Update pagination controls */}
      {!loading && forms.length > 0 && (
        <div className="mt-6 flex justify-center">
          <nav className="flex items-center gap-2">
            <button
              onClick={() => handlePageChange(paginationState[activeTab].currentPage - 1)}
              disabled={!paginationState[activeTab].hasPrevPage}
              className={`px-3 py-1 rounded-md ${
                paginationState[activeTab].hasPrevPage
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-200 text-gray-500 cursor-not-allowed'
              }`}
            >
              הקודם
            </button>
            
            <span className="px-4 py-1 text-sm text-gray-700">
              עמוד {paginationState[activeTab].currentPage} מתוך {paginationState[activeTab].totalPages}
            </span>
            
            <button
              onClick={() => handlePageChange(paginationState[activeTab].currentPage + 1)}
              disabled={!paginationState[activeTab].hasNextPage}
              className={`px-3 py-1 rounded-md ${
                paginationState[activeTab].hasNextPage
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-200 text-gray-500 cursor-not-allowed'
              }`}
            >
              הבא
            </button>
          </nav>
        </div>
      )}
    </div>
  );
};

export default InventoryFormList;