import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { 
  Calendar, Clock, Check, X, Link, ChevronDown, ChevronUp, FileText, 
  Building, Download, Mail, Clipboard, Send, AlertTriangle, CheckCircle2, 
  RefreshCw, BarChart2, Users, Package, Clock8, Trash2
} from 'lucide-react';
import { API_URL } from '../../config';

export default function DistributionsList() {
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedBatches, setExpandedBatches] = useState({});
  const [expandedForms, setExpandedForms] = useState({});
  const [exporting, setExporting] = useState(null);
  const [branchData, setBranchData] = useState({});
  const [sendingCombinedEmail, setSendingCombinedEmail] = useState(null);
  const [statistics, setStatistics] = useState({
    totalForms: 0,
    pendingForms: 0,
    completedForms: 0,
    expiredForms: 0,
    totalBranches: 0,
    totalProducts: 0,
    batchStats: {}
  });
  const [deletingBatch, setDeletingBatch] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchDistributions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/distributions`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data.batches) {
          setBatches(response.data.batches);
          
          // Calculate overall and per-batch statistics
          const stats = {
            totalForms: 0,
            pendingForms: 0,
            completedForms: 0,
            expiredForms: 0,
            totalBranches: new Set(),
            totalProducts: new Set(),
            batchStats: {}
          };
          
          response.data.batches.forEach(batch => {
            // Initialize batch statistics
            const batchStat = {
              totalForms: 0,
              pendingForms: 0,
              completedForms: 0,
              expiredForms: 0,
              branches: new Set(),
              products: new Set(),
              respondedBranches: new Set(),
              pendingBranches: new Set(),
              expiredBranches: new Set()
            };

            batch.forms.forEach(form => {
              // Update overall stats
              stats.totalForms++;
              stats.totalBranches.add(form.branchName);
              form.items.forEach(item => stats.totalProducts.add(item.productName));

              // Update batch-specific stats
              batchStat.totalForms++;
              batchStat.branches.add(form.branchName);
              form.items.forEach(item => batchStat.products.add(item.productName));

              // Track branch status
              if (form.status === 'completed') {
                stats.completedForms++;
                batchStat.completedForms++;
                batchStat.respondedBranches.add(form.branchName);
              } else if (form.status === 'pending') {
                stats.pendingForms++;
                batchStat.pendingForms++;
                batchStat.pendingBranches.add(form.branchName);
              } else if (form.status === 'expired') {
                stats.expiredForms++;
                batchStat.expiredForms++;
                batchStat.expiredBranches.add(form.branchName);
              }
            });

            // Convert Sets to counts for the batch
            stats.batchStats[batch.id] = {
              ...batchStat,
              totalBranches: batchStat.branches.size,
              totalProducts: batchStat.products.size,
              respondedBranchesCount: batchStat.respondedBranches.size,
              pendingBranchesCount: batchStat.pendingBranches.size,
              expiredBranchesCount: batchStat.expiredBranches.size,
              respondedBranches: Array.from(batchStat.respondedBranches),
              pendingBranches: Array.from(batchStat.pendingBranches),
              expiredBranches: Array.from(batchStat.expiredBranches)
            };
          });
          
          setStatistics({
            ...stats,
            totalBranches: stats.totalBranches.size,
            totalProducts: stats.totalProducts.size,
            batchStats: stats.batchStats
          });
          
          if (response.data.batches.length > 0) {
            const initialExpandedState = {};
            initialExpandedState[response.data.batches[0].id] = true;
            setExpandedBatches(initialExpandedState);
          }
        } else {
          const legacyBatch = {
            id: 'legacy',
            name: 'כל החלוקות',
            timestamp: new Date(),
            forms: response.data
          };
          setBatches([legacyBatch]);
          setExpandedBatches({ legacy: true });
        }

        await fetchBranchData(response.data.batches || [response.data]);
      } catch (error) {
        console.error('Error fetching distribution forms:', error);
        toast.error('שגיאה בטעינת טפסי חלוקה');
      } finally {
        setLoading(false);
      }
    };

    fetchDistributions();
  }, []);

  // Fetch branch details including email addresses
  const fetchBranchData = async (batchesData) => {
    try {
      const token = localStorage.getItem('token');
      const uniqueBranchIds = new Set();
      
      // Extract unique branch IDs from all forms in all batches
      batchesData.forEach(batch => {
        const forms = batch.forms || [];
        forms.forEach(form => {
          if (form.branch) {
            uniqueBranchIds.add(form.branch.toString());
          }
        });
      });
      
      if (uniqueBranchIds.size === 0) return;
      
      // Fetch branch details for all branch IDs
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      const branchesMap = {};
      response.data.forEach(branch => {
        branchesMap[branch._id] = branch;
      });
      
      setBranchData(branchesMap);
    } catch (error) {
      console.error('Error fetching branch data:', error);
    }
  };

  const toggleBatch = (batchId) => {
    setExpandedBatches(prev => ({
      ...prev,
      [batchId]: !prev[batchId]
    }));
  };

  const toggleForm = (formId) => {
    setExpandedForms(prev => ({
      ...prev,
      [formId]: !prev[formId]
    }));
  };

  const copyAccessLink = (accessToken) => {
    const link = `${window.location.origin}/distribution-form/${accessToken}`;
    navigator.clipboard.writeText(link);
    toast.success('קישור הועתק ללוח');
  };

  const copyUnifiedLink = (batchId) => {
    const link = `${window.location.origin}/distribution-form/batch/${batchId}`;
    navigator.clipboard.writeText(link);
    toast.success('קישור מאוחד לקבוצה זו הועתק ללוח');
  };

  const handleEmailForm = (form, event) => {
    event.stopPropagation();
    
    // Get branch email if available
    const branchInfo = branchData[form.branch];
    const branchEmail = branchInfo?.email;
    
    if (!branchEmail) {
      toast.error('לא נמצאה כתובת מייל לסניף זה');
      return;
    }

    const formUrl = `${window.location.origin}/distribution-form/${form.accessToken}`;
    const branchName = form.branchName || 'סניף לא ידוע';
    const today = new Date().toLocaleDateString('he-IL');

    try {
      // Create email content with the URL on a separate line
      const subject = encodeURIComponent(`טופס חלוקות סחורה - ${branchName} - ${today}`);
      const body = encodeURIComponent(`שלום,

נא למלא את טופס חלוקות הסחורה בקישור הבא:

${formUrl}

לחץ על הקישור או העתק אותו לדפדפן.

בברכה`);

      // Create mailto URL
      const mailtoUrl = `mailto:${encodeURIComponent(branchEmail)}?subject=${subject}&body=${body}`;

      // Try to open default email client
      window.location.href = mailtoUrl;
      
      toast.success('נפתח חלון מייל חדש');
    } catch (error) {
      console.error('Error opening email client:', error);
      
      // Fallback: Copy to clipboard
      navigator.clipboard.writeText(`To: ${branchEmail}
Subject: טופס חלוקות סחורה - ${branchName} - ${today}

${formUrl}`)
        .then(() => {
          toast.success('הטקסט הועתק ללוח - לא ניתן היה לפתוח את תוכנת המייל');
        })
        .catch(() => {
          toast.error('שגיאה בפתיחת המייל ובהעתקת הטקסט');
        });
    }
  };

  const handleCopyEmailText = (form, event) => {
    event.stopPropagation();
    
    const formUrl = `${window.location.origin}/distribution-form/${form.accessToken}`;
    const branchName = form.branchName || 'סניף לא ידוע';
    const today = new Date().toLocaleDateString('he-IL');
    
    const text = `טופס חלוקות סחורה - ${branchName} - ${today}

שלום,

נא למלא את טופס חלוקות הסחורה בקישור הבא:

${formUrl}

לחץ על הקישור או העתק אותו לדפדפן.

בברכה`;

    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('הודעת המייל הועתקה ללוח');
      })
      .catch(() => {
        toast.error('שגיאה בהעתקת הטקסט');
      });
  };

  // Send email with all forms for a specific branch
  const handleSendCombinedEmail = (branchGroup, event) => {
    event.stopPropagation();
    
    // Find branch ID from the first form in the group
    if (!branchGroup.forms || branchGroup.forms.length === 0) {
      toast.error('אין טפסים לשליחה לסניף זה');
      return;
    }
    
    const firstForm = branchGroup.forms[0];
    const branchId = firstForm.branch;
    setSendingCombinedEmail(branchGroup.branchName);
    
    // Get branch email
    const branchInfo = branchData[branchId];
    const branchEmail = branchInfo?.email;
    
    if (!branchEmail) {
      toast.error('לא נמצאה כתובת מייל לסניף זה');
      setSendingCombinedEmail(null);
      return;
    }
    
    // Create list of forms with links
    const formLinks = branchGroup.forms.map(form => {
      const formUrl = `${window.location.origin}/distribution-form/${form.accessToken}`;
      const formName = form.title || 'טופס חלוקה';
      return `* ${formName}: ${formUrl}`;
    }).join('\n\n');
    
    const branchName = branchGroup.branchName;
    const today = new Date().toLocaleDateString('he-IL');
    
    try {
      // Create email content
      const subject = encodeURIComponent(`טפסי חלוקות סחורה - ${branchName} - ${today}`);
      const body = encodeURIComponent(`שלום,

נא למלא את טפסי חלוקות הסחורה בקישורים הבאים:

${formLinks}

לחץ על הקישורים או העתק אותם לדפדפן.

בברכה`);

      // Create mailto URL
      const mailtoUrl = `mailto:${encodeURIComponent(branchEmail)}?subject=${subject}&body=${body}`;

      // Try to open default email client
      window.location.href = mailtoUrl;
      
      toast.success('נפתח חלון מייל חדש עם כל הטפסים');
    } catch (error) {
      console.error('Error opening email client:', error);
      
      // Fallback: Copy to clipboard
      navigator.clipboard.writeText(`To: ${branchEmail}
Subject: טפסי חלוקות סחורה - ${branchName} - ${today}

שלום,

נא למלא את טפסי חלוקות הסחורה בקישורים הבאים:

${formLinks}

לחץ על הקישורים או העתק אותם לדפדפן.

בברכה`)
        .then(() => {
          toast.success('הטקסט הועתק ללוח - לא ניתן היה לפתוח את תוכנת המייל');
        })
        .catch(() => {
          toast.error('שגיאה בפתיחת המייל ובהעתקת הטקסט');
        });
    } finally {
      setSendingCombinedEmail(null);
    }
  };

  // Copy combined email text for all forms in a branch
  const handleCopyCombinedEmailText = (branchGroup, event) => {
    event.stopPropagation();
    
    if (!branchGroup.forms || branchGroup.forms.length === 0) {
      toast.error('אין טפסים לסניף זה');
      return;
    }
    
    // Create list of forms with links
    const formLinks = branchGroup.forms.map(form => {
      const formUrl = `${window.location.origin}/distribution-form/${form.accessToken}`;
      const formName = form.title || 'טופס חלוקה';
      return `* ${formName}: ${formUrl}`;
    }).join('\n\n');
    
    const branchName = branchGroup.branchName;
    const today = new Date().toLocaleDateString('he-IL');
    
    const text = `טפסי חלוקות סחורה - ${branchName} - ${today}

שלום,

נא למלא את טפסי חלוקות הסחורה בקישורים הבאים:

${formLinks}

לחץ על הקישורים או העתק אותם לדפדפן.

בברכה`;

    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('הודעת המייל המשולב הועתקה ללוח');
      })
      .catch(() => {
        toast.error('שגיאה בהעתקת הטקסט');
      });
  };

  const exportBatchToExcel = async (batchId, event) => {
    event.stopPropagation();
    
    try {
      setExporting(batchId);
      const token = localStorage.getItem('token');
      
      const response = await axios.get(
        `${API_URL}/api/distributions/export/${batchId}`, 
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        }
      );
      
      // Create download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      // Get the batch name for the filename
      const batch = batches.find(b => b.id === batchId);
      const fileName = `${batch?.name || 'distribution'}.xlsx`;
      
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      
      toast.success('הקובץ הורד בהצלחה');
    } catch (error) {
      console.error('Error exporting batch to Excel:', error);
      toast.error('שגיאה בייצוא לאקסל');
    } finally {
      setExporting(null);
    }
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case 'pending':
        return <span className="px-2 py-1 text-xs rounded-full bg-yellow-100 text-yellow-800">ממתין</span>;
      case 'completed':
        return <span className="px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">הושלם</span>;
      case 'expired':
        return <span className="px-2 py-1 text-xs rounded-full bg-red-100 text-red-800">פג תוקף</span>;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('he-IL');
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('he-IL', { hour: '2-digit', minute: '2-digit' });
  };

  const getBatchSummary = (batch) => {
    const forms = batch.forms || [];
    const totalForms = forms.length;
    const completedForms = forms.filter(form => form.status === 'completed').length;
    const pendingForms = forms.filter(form => form.status === 'pending').length;
    const expiredForms = forms.filter(form => form.status === 'expired').length;
    
    return { totalForms, completedForms, pendingForms, expiredForms };
  };

  // Group forms by branch name
  const getBranchGroups = (forms) => {
    const branchGroups = {};
    
    forms.forEach(form => {
      const branchName = form.branchName;
      if (!branchGroups[branchName]) {
        branchGroups[branchName] = [];
      }
      branchGroups[branchName].push(form);
    });
    
    // Convert to array of objects
    return Object.entries(branchGroups).map(([branchName, forms]) => ({
      branchName,
      forms
    }));
  };

  // Add this new component for progress circle
  const ProgressCircle = ({ percentage, size = 60, strokeWidth = 5, color }) => {
    const radius = (size - strokeWidth) / 2;
    const circumference = radius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
      <div className="relative" style={{ width: size, height: size }}>
        <svg className="transform -rotate-90" width={size} height={size}>
          {/* Background circle */}
          <circle
            className="text-gray-200"
            strokeWidth={strokeWidth}
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
          {/* Progress circle */}
          <circle
            className={color}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx={size / 2}
            cy={size / 2}
          />
        </svg>
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-sm font-semibold">{Math.round(percentage)}%</span>
        </div>
      </div>
    );
  };

  // New BatchStatistics component
  const BatchStatistics = ({ batchStats }) => {
    if (!batchStats) return null;

    const totalBranches = batchStats.totalBranches;
    const responseRate = (batchStats.respondedBranchesCount / totalBranches) * 100;
    const pendingRate = (batchStats.pendingBranchesCount / totalBranches) * 100;
    const expiredRate = (batchStats.expiredBranchesCount / totalBranches) * 100;

    return (
      <div className="mt-4 bg-white rounded-lg p-6 border">
        {/* Progress Overview */}
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-lg font-semibold text-gray-900">סטטוס מענה סניפים</h3>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <RefreshCw className="h-4 w-4" />
            <span>עודכן לאחרונה: {new Date().toLocaleTimeString('he-IL')}</span>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
          {/* Left side - Progress circles and stats */}
          <div className="lg:col-span-5">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex justify-around items-center mb-6">
                <div className="text-center">
                  <ProgressCircle 
                    percentage={responseRate} 
                    color="text-green-500"
                  />
                  <p className="mt-2 text-sm font-medium text-gray-600">השיבו</p>
                </div>
                <div className="text-center">
                  <ProgressCircle 
                    percentage={pendingRate} 
                    color="text-yellow-500"
                  />
                  <p className="mt-2 text-sm font-medium text-gray-600">ממתינים</p>
                </div>
                <div className="text-center">
                  <ProgressCircle 
                    percentage={expiredRate} 
                    color="text-red-500"
                  />
                  <p className="mt-2 text-sm font-medium text-gray-600">פג תוקף</p>
                </div>
              </div>

              <div className="space-y-3">
                <div className="flex justify-between items-center p-2 bg-white rounded">
                  <span className="text-sm font-medium text-gray-600">סך הכל סניפים</span>
                  <span className="text-lg font-semibold text-blue-600">{totalBranches}</span>
                </div>
                <div className="flex justify-between items-center p-2 bg-white rounded">
                  <span className="text-sm font-medium text-gray-600">סניפים שהשיבו</span>
                  <span className="text-lg font-semibold text-green-600">{batchStats.respondedBranchesCount}</span>
                </div>
                <div className="flex justify-between items-center p-2 bg-white rounded">
                  <span className="text-sm font-medium text-gray-600">ממתינים למענה</span>
                  <span className="text-lg font-semibold text-yellow-600">{batchStats.pendingBranchesCount}</span>
                </div>
                <div className="flex justify-between items-center p-2 bg-white rounded">
                  <span className="text-sm font-medium text-gray-600">פג תוקף</span>
                  <span className="text-lg font-semibold text-red-600">{batchStats.expiredBranchesCount}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Branch lists */}
          <div className="lg:col-span-7">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 h-full">
              {/* Responded Branches */}
              <div className="bg-green-50 rounded-lg flex flex-col h-[400px]">
                <div className="p-3 border-b border-green-100">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-green-800">סניפים שהשיבו</h4>
                    <span className="bg-green-100 text-green-800 text-xs font-medium px-2 py-1 rounded-full">
                      {batchStats.respondedBranchesCount}
                    </span>
                  </div>
                </div>
                <div className="flex-1 overflow-hidden p-3">
                  <div className="h-full overflow-y-auto pr-2 custom-scrollbar">
                    <div className="space-y-2">
                      {batchStats.respondedBranches.map(branch => (
                        <div key={branch} className="flex items-center gap-2 text-sm text-green-700 bg-green-100/50 p-2 rounded hover:bg-green-100 transition-colors">
                          <Check className="h-4 w-4 flex-shrink-0" />
                          <span className="truncate">{branch}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Pending Branches */}
              <div className="bg-yellow-50 rounded-lg flex flex-col h-[400px]">
                <div className="p-3 border-b border-yellow-100">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-yellow-800">ממתינים למענה</h4>
                    <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2 py-1 rounded-full">
                      {batchStats.pendingBranchesCount}
                    </span>
                  </div>
                </div>
                <div className="flex-1 overflow-hidden p-3">
                  <div className="h-full overflow-y-auto pr-2 custom-scrollbar">
                    <div className="space-y-2">
                      {batchStats.pendingBranches.map(branch => (
                        <div key={branch} className="flex items-center gap-2 text-sm text-yellow-700 bg-yellow-100/50 p-2 rounded hover:bg-yellow-100 transition-colors">
                          <Clock className="h-4 w-4 flex-shrink-0" />
                          <span className="truncate">{branch}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Expired Branches */}
              <div className="bg-red-50 rounded-lg flex flex-col h-[400px]">
                <div className="p-3 border-b border-red-100">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-red-800">פג תוקף</h4>
                    <span className="bg-red-100 text-red-800 text-xs font-medium px-2 py-1 rounded-full">
                      {batchStats.expiredBranchesCount}
                    </span>
                  </div>
                </div>
                <div className="flex-1 overflow-hidden p-3">
                  <div className="h-full overflow-y-auto pr-2 custom-scrollbar">
                    <div className="space-y-2">
                      {batchStats.expiredBranches.map(branch => (
                        <div key={branch} className="flex items-center gap-2 text-sm text-red-700 bg-red-100/50 p-2 rounded hover:bg-red-100 transition-colors">
                          <AlertTriangle className="h-4 w-4 flex-shrink-0" />
                          <span className="truncate">{branch}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Add this CSS to your global styles or component
  const customScrollbarStyles = `
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 6px;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  `;

  // Add the styles to the document
  if (!document.getElementById('custom-scrollbar-styles')) {
    const styleSheet = document.createElement('style');
    styleSheet.id = 'custom-scrollbar-styles';
    styleSheet.textContent = customScrollbarStyles;
    document.head.appendChild(styleSheet);
  }

  // Add delete batch function
  const handleDeleteBatch = async (batchId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/distributions/batch/${batchId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      // Remove batch from state
      setBatches(prev => prev.filter(batch => batch.id !== batchId));
      toast.success('החלוקה נמחקה בהצלחה');
      setShowDeleteConfirm(false);
      setDeletingBatch(null);
    } catch (error) {
      console.error('Error deleting batch:', error);
      toast.error('שגיאה במחיקת החלוקה');
    }
  };

  // Add confirmation dialog component
  const DeleteConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
          <h3 className="text-lg font-medium text-gray-900 mb-4">אתה בטוח שאתה רוצה למחוק חלוקה זו?</h3>
          <p className="text-sm text-gray-500 mb-6">פעולה זו תמחק את כל הטפסים והנתונים הקשורים לחלוקה זו ולא ניתן יהיה לשחזר אותם.</p>
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              ביטול
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              מחק חלוקה
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="p-6 flex justify-center">
        <div className="animate-pulse">טוען טפסי חלוקה...</div>
      </div>
    );
  }

  return (
    <div className="p-6">
      {/* Add DeleteConfirmationDialog */}
      <DeleteConfirmationDialog
        isOpen={showDeleteConfirm}
        onClose={() => {
          setShowDeleteConfirm(false);
          setDeletingBatch(null);
        }}
        onConfirm={() => handleDeleteBatch(deletingBatch)}
      />

      {/* Header with action button */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-2xl font-semibold">לוח בקרה - טפסי חלוקה</h2>
          <p className="text-gray-600 mt-1">ניהול וצפייה בכל טפסי החלוקה במערכת</p>
        </div>
        <a
          href="/dashboard/distributions/upload"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          טופס חלוקה חדש
        </a>
      </div>

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow-sm p-6 border">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">סך הכל טפסים</p>
              <h3 className="text-2xl font-semibold mt-1">{statistics.totalForms}</h3>
            </div>
            <div className="bg-blue-100 p-3 rounded-full">
              <FileText className="h-6 w-6 text-blue-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-600">
            <BarChart2 className="h-4 w-4 ml-1" />
            <span>פעילים: {statistics.pendingForms + statistics.completedForms}</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6 border">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">סניפים</p>
              <h3 className="text-2xl font-semibold mt-1">{statistics.totalBranches}</h3>
            </div>
            <div className="bg-green-100 p-3 rounded-full">
              <Building className="h-6 w-6 text-green-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-600">
            <Users className="h-4 w-4 ml-1" />
            <span>משתתפים פעילים</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6 border">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">מוצרים</p>
              <h3 className="text-2xl font-semibold mt-1">{statistics.totalProducts}</h3>
            </div>
            <div className="bg-purple-100 p-3 rounded-full">
              <Package className="h-6 w-6 text-purple-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center text-sm text-gray-600">
            <CheckCircle2 className="h-4 w-4 ml-1" />
            <span>בתהליך חלוקה</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6 border">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">סטטוס טפסים</p>
              <h3 className="text-2xl font-semibold mt-1">{statistics.completedForms}</h3>
            </div>
            <div className="bg-yellow-100 p-3 rounded-full">
              <Clock8 className="h-6 w-6 text-yellow-600" />
            </div>
          </div>
          <div className="mt-4 flex items-center justify-between text-sm">
            <span className="text-green-600">הושלמו: {statistics.completedForms}</span>
            <span className="text-yellow-600">ממתינים: {statistics.pendingForms}</span>
            <span className="text-red-600">פגי תוקף: {statistics.expiredForms}</span>
          </div>
        </div>
      </div>

      {/* Link types explanation */}
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 border border-blue-200 rounded-lg p-6 mb-8">
        <h3 className="font-medium text-blue-800 mb-4 text-lg">סוגי קישורים לשיתוף:</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <div className="flex items-start gap-3">
              <div className="bg-blue-100 rounded-full w-8 h-8 flex items-center justify-center text-lg font-medium text-blue-600">1</div>
              <div>
                <p className="font-medium text-gray-900">קישור מאוחד לחלוקה זו</p>
                <p className="text-gray-600 mt-1">קישור אחד לכל קבוצת חלוקות שבו המשתמש יבחר את הסניף שלו ויראה את כל הטפסים הרלוונטיים אליו מאותה קבוצה</p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <div className="flex items-start gap-3">
              <div className="bg-blue-100 rounded-full w-8 h-8 flex items-center justify-center text-lg font-medium text-blue-600">2</div>
              <div>
                <p className="font-medium text-gray-900">קישור ספציפי</p>
                <p className="text-gray-600 mt-1">קישור נפרד לכל טופס - המשתמש יגיע ישירות לטופס הספציפי</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Batches List */}
      {batches.length === 0 ? (
        <div className="text-center py-10 bg-gray-50 rounded-lg border">
          <FileText className="h-12 w-12 text-gray-400 mx-auto mb-3" />
          <p className="text-gray-500">אין טפסי חלוקה זמינים</p>
        </div>
      ) : (
        <div className="space-y-6">
          {batches.map((batch) => {
            const { totalForms, completedForms, pendingForms, expiredForms } = getBatchSummary(batch);
            const isExpanded = expandedBatches[batch.id];
            const branchGroups = getBranchGroups(batch.forms);
            const batchStats = statistics.batchStats[batch.id];
            
            return (
              <div key={batch.id} className="border rounded-lg bg-white shadow-sm overflow-hidden">
                <div className="p-4 bg-gray-50 flex justify-between items-center">
                  <div className="flex items-center gap-2">
                    <FileText className="h-5 w-5 text-blue-600" />
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">{batch.name}</h3>
                      <p className="text-sm text-gray-500">
                        נוצר ב-{formatDate(batch.timestamp)} | 
                        סך הכל: {totalForms} טפסים (
                        <span className="text-green-600">{completedForms} הושלמו</span>, 
                        <span className="text-yellow-600">{pendingForms} ממתינים</span>, 
                        <span className="text-red-600">{expiredForms} פגי תוקף</span>)
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => {
                        setDeletingBatch(batch.id);
                        setShowDeleteConfirm(true);
                      }}
                      className="inline-flex items-center px-3 py-1.5 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      title="מחק חלוקה"
                    >
                      <Trash2 className="h-4 w-4 ml-1" />
                      מחק חלוקה
                    </button>
                    <button
                      onClick={() => copyUnifiedLink(batch.id)}
                      className="inline-flex items-center px-3 py-1.5 border border-blue-300 rounded-md shadow-sm text-sm font-medium text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      title="העתק קישור מאוחד לחלוקה זו"
                    >
                      <Link className="h-4 w-4 ml-1" />
                      קישור מאוחד לחלוקה זו
                    </button>
                    <button
                      onClick={(e) => exportBatchToExcel(batch.id, e)}
                      disabled={exporting === batch.id}
                      className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      {exporting === batch.id ? (
                        <span className="inline-flex items-center">
                          <span className="animate-spin mr-2 h-4 w-4 border-t-2 border-b-2 border-gray-900 rounded-full"></span>
                          מייצא...
                        </span>
                      ) : (
                        <>
                          <Download className="h-4 w-4 ml-1" />
                          ייצוא לאקסל
                        </>
                      )}
                    </button>
                    <div 
                      className="text-gray-400 cursor-pointer"
                      onClick={() => toggleBatch(batch.id)}
                    >
                      {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </div>
                  </div>
                </div>

                {/* Add batch statistics */}
                {batchStats && <BatchStatistics batchStats={batchStats} />}

                {isExpanded && (
                  <div className="p-4 space-y-4 border-t">
                    {branchGroups.map((group, index) => (
                      <div key={`${batch.id}-${group.branchName}-${index}`} className="border rounded-lg overflow-hidden">
                        <div className="bg-blue-50 p-3 flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <Building className="h-5 w-5 text-blue-600" />
                            <h4 className="font-medium">{group.branchName}</h4>
                            <span className="text-sm text-gray-500 mr-2">({group.forms.length} טפסים)</span>
                          </div>
                          
                          {group.forms.length > 1 && (
                            <div className="flex space-x-2">
                              <button
                                onClick={(e) => handleSendCombinedEmail(group, e)}
                                className="inline-flex items-center px-3 py-1.5 border border-blue-300 rounded-md shadow-sm text-sm font-medium text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-2"
                                title="פתח תוכנת מייל"
                              >
                                <Mail className="h-4 w-4 ml-1" />
                                פתח בתוכנת מייל
                              </button>
                              <button
                                onClick={(e) => handleCopyCombinedEmailText(group, e)}
                                className="inline-flex items-center px-2 py-1.5 border border-blue-300 rounded-md shadow-sm text-sm font-medium text-blue-700 bg-blue-50 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                title="העתק טקסט לאימייל משולב"
                              >
                                <Clipboard className="h-4 w-4" />
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="p-3 space-y-3">
                          {group.forms.map((form) => (
                            <div
                              key={form._id}
                              className="border rounded-lg p-4 bg-white shadow-sm"
                            >
                              <div className="flex justify-between items-start">
                                <div>
                                  <div className="flex items-center gap-2">
                                    <h5 className="font-medium">{form.title}</h5>
                                    {getStatusBadge(form.status)}
                                  </div>
                                  <div className="flex mt-2 gap-4 flex-wrap">
                                    <div className="flex items-center text-sm text-gray-500">
                                      <Calendar className="h-4 w-4 ml-1" />
                                      נוצר: {formatDate(form.createdAt)}
                                    </div>
                                    <div className="flex items-center text-sm text-gray-500">
                                      <Clock className="h-4 w-4 ml-1" />
                                      תפוגה: {formatDate(form.expiresAt)}
                                    </div>
                                    <div className="flex items-center text-sm text-gray-500">
                                      <FileText className="h-4 w-4 ml-1" />
                                      פריטים: {form.items.length}
                                    </div>
                                  </div>
                                  
                                  {form.status === 'completed' && (
                                    <div className="mt-2">
                                      <p className="text-sm text-gray-500">
                                        הושלם: {formatDate(form.completedAt)} {formatTime(form.completedAt)}
                                      </p>
                                      <div className="mt-2 grid grid-cols-4 gap-2">
                                        <div className="flex gap-2 items-center text-sm">
                                          <span className="flex items-center text-green-600"><Check size={16} className="ml-1" /> מאושר: </span>
                                          <span className="font-medium">{form.items.filter(item => item.response === 'approved').length}</span>
                                        </div>
                                        <div className="flex gap-2 items-center text-sm">
                                          <span className="flex items-center text-amber-600"><span className="ml-1">↓</span> הפחתה: </span>
                                          <span className="font-medium">{form.items.filter(item => item.response === 'reduced').length}</span>
                                        </div>
                                        <div className="flex gap-2 items-center text-sm">
                                          <span className="flex items-center text-blue-600"><span className="ml-1">↑</span> הגדלה: </span>
                                          <span className="font-medium">{form.items.filter(item => item.response === 'increased').length}</span>
                                        </div>
                                        <div className="flex gap-2 items-center text-sm">
                                          <span className="flex items-center text-red-600"><X size={16} className="ml-1" /> ביטול: </span>
                                          <span className="font-medium">{form.items.filter(item => item.response === 'rejected').length}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="flex space-x-2">
                                  <button
                                    onClick={(e) => handleEmailForm(form, e)}
                                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-2"
                                    title="פתח בתוכנת מייל"
                                  >
                                    <Mail className="h-4 w-4 ml-1" />
                                    פתח בתוכנת מייל
                                  </button>
                                  <button
                                    onClick={(e) => handleCopyEmailText(form, e)}
                                    className="inline-flex items-center px-2 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ml-2"
                                    title="העתק טקסט למייל"
                                  >
                                    <Clipboard className="h-4 w-4" />
                                  </button>
                                  <button
                                    onClick={() => copyAccessLink(form.accessToken)}
                                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                  >
                                    <Link className="h-4 w-4 ml-1" />
                                    העתק קישור
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}