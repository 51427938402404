import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { FileText, CheckCircle, XCircle, TruckIcon, Package, FilterIcon, Search, Calendar, Tag, Hash, Edit, Save } from 'lucide-react';
import { 
  getOfficeSupplyOrders, 
  updateOfficeSupplyOrderStatus, 
  updateOfficeSupplyOrder,
  getBranches,
  getAreaManagers,
  exportOfficeSupplyOrders
} from '../../utils/api';
import { OfficeSupplyOrderPDF } from '../OfficeSupplies/OfficeSupplyOrderPDFGenerator';
import { PDFDownloadLink } from '@react-pdf/renderer';

const AdminOfficeSuppliesOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [areaManagers, setAreaManagers] = useState([]);
  const [filters, setFilters] = useState({
    branchId: '',
    areaManager: '',
    status: ''
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedItems, setEditedItems] = useState([]);
  const [editedNotes, setEditedNotes] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  
  // Export modal state
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportDateRange, setExportDateRange] = useState({
    startDate: '',
    endDate: ''
  });
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [filters]);

  const fetchData = async () => {
    try {
      // Fetch branches, area managers, and orders in parallel
      const [branchesData, areaManagersData, ordersData] = await Promise.all([
        fetchBranches(),
        fetchAreaManagers(),
        fetchOrders()
      ]);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      toast.error('שגיאה בטעינת נתונים');
    }
  };

  const fetchBranches = async () => {
    try {
      const data = await getBranches();
      setBranches(data);
      return data;
    } catch (error) {
      console.error('Error fetching branches:', error);
      return [];
    }
  };

  const fetchAreaManagers = async () => {
    try {
      // Use the dedicated area managers API function
      const data = await getAreaManagers();
      setAreaManagers(data);
      return data;
    } catch (error) {
      console.error('Error fetching area managers:', error);
      return [];
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await getOfficeSupplyOrders(filters);
      setOrders(response);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      toast.error('שגיאה בטעינת הזמנות');
      setLoading(false);
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      await updateOfficeSupplyOrderStatus(orderId, newStatus);
      toast.success('סטטוס הזמנה עודכן בהצלחה');
      
      // Update the order in the UI
      setOrders(prevOrders => prevOrders.map(order => 
        order._id === orderId ? { ...order, status: newStatus } : order
      ));
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('שגיאה בעדכון סטטוס הזמנה');
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      branchId: '',
      areaManager: '',
      status: ''
    });
  };

  // Function to handle Excel export
  const handleExport = async () => {
    // Validate date range
    const { startDate, endDate } = exportDateRange;
    if (!startDate || !endDate) {
      toast.error('נא לבחור תאריך התחלה וסיום');
      return;
    }
    
    // Make sure start date is before end date
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (start > end) {
      toast.error('תאריך התחלה חייב להיות לפני תאריך סיום');
      return;
    }
    
    setIsExporting(true);
    try {
      // Apply current filters to the export
      const exportFilters = { ...filters };
      const blob = await exportOfficeSupplyOrders(exportDateRange, exportFilters);
      
      // Create a download link and trigger it
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      const fileName = `הזמנות-ציוד-משרדי-${new Date().toISOString().slice(0, 10)}.xlsx`;
      
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      setIsExportModalOpen(false);
      toast.success('הקובץ יורד בהצלחה');
    } catch (error) {
      console.error('Export error:', error);
      toast.error('שגיאה בייצוא הנתונים');
    } finally {
      setIsExporting(false);
    }
  };

  // PDF generation is now handled by the @react-pdf/renderer
  // We still keep a function to handle cases where we need to show loading state
  const generatePdfButton = (order) => {
    return (
      <PDFDownloadLink 
        document={<OfficeSupplyOrderPDF order={order} />} 
        fileName={`order-${order._id}.pdf`}
        className="p-2 text-blue-600 hover:text-blue-800 rounded-md hover:bg-blue-50 transition-colors flex items-center"
      >
        {({ blob, url, loading, error }) => 
          loading ? 
            <span className="text-gray-400 flex items-center"><span className="animate-spin mr-1">⏳</span> טוען...</span> : 
            <span className="flex items-center"><FileText size={16} className="ml-1" /> הורד PDF</span>
        }
      </PDFDownloadLink>
    );
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'bg-blue-100 text-blue-800';
      case 'rejected':
        return 'bg-red-100 text-red-800';
      case 'delivered':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-yellow-100 text-yellow-800';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'approved':
        return 'אושר';
      case 'rejected':
        return 'נדחה';
      case 'delivered':
        return 'נמסר';
      default:
        return 'ממתין לאישור';
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('he-IL');
  };

  const viewOrderDetails = (order) => {
    setSelectedOrder(order);
    setEditedItems([...order.items]);
    setEditedNotes(order.notes || '');
    setIsEditing(false);
    setIsModalOpen(true);
  };

  const toggleEditMode = () => {
    console.log('Toggle Edit Mode called, current isEditing:', isEditing);
    console.log('Selected order status:', selectedOrder.status);
    if (isEditing) {
      // Cancel editing - reset to original values
      setEditedItems([...selectedOrder.items]);
      setEditedNotes(selectedOrder.notes || '');
    }
    setIsEditing(!isEditing);
    console.log('isEditing set to:', !isEditing);
  };

  const handleItemQuantityChange = (index, newQuantity) => {
    // Convert to number and handle NaN
    const numericQuantity = parseInt(newQuantity) || 0;
    
    // Enforce limits: minimum 0, maximum 1000
    if (numericQuantity < 0 || numericQuantity > 1000) {
      if (numericQuantity > 1000) {
        toast.error('כמות מקסימלית להזמנה היא 1000');
      }
      return;
    }

    const updatedItems = [...editedItems];
    updatedItems[index] = {
      ...updatedItems[index],
      quantity: numericQuantity
    };
    setEditedItems(updatedItems);
  };

  const calculateUpdatedTotal = () => {
    return editedItems.reduce((total, item) => {
      return total + (item.price * item.quantity);
    }, 0);
  };

  const saveOrderChanges = async () => {
    console.log('saveOrderChanges called');
    if (!selectedOrder) {
      console.error('No selected order');
      return;
    }
    
    // Validate that at least one item has a quantity > 0
    const hasItems = editedItems.some(item => item.quantity > 0);
    if (!hasItems) {
      toast.error('ההזמנה חייבת לכלול לפחות פריט אחד');
      return;
    }

    setIsSaving(true);
    try {
      console.log('Preparing to save order changes');
      // Filter out items with zero quantity
      const itemsToSave = editedItems.filter(item => item.quantity > 0);
      
      // Create updated order data
      const updatedOrderData = {
        items: itemsToSave,
        notes: editedNotes,
        totalAmount: calculateUpdatedTotal()
      };

      console.log('Sending updated order data:', updatedOrderData);
      
      // Call API to update order
      await updateOfficeSupplyOrder(selectedOrder._id, updatedOrderData);
      
      // Update the order in the UI
      const updatedOrder = {
        ...selectedOrder,
        items: itemsToSave,
        notes: editedNotes,
        totalAmount: calculateUpdatedTotal()
      };
      
      setSelectedOrder(updatedOrder);
      setOrders(prevOrders => prevOrders.map(order => 
        order._id === selectedOrder._id ? updatedOrder : order
      ));
      
      setIsEditing(false);
      toast.success('ההזמנה עודכנה בהצלחה');
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('שגיאה בעדכון ההזמנה');
    } finally {
      setIsSaving(false);
    }
  };

  const getOrderSubmittedBy = (order) => {
    if (order.requesterName) {
      return <span className="font-medium">{order.requesterName} <span className="text-xs text-gray-500">(הזמנה ציבורית)</span></span>;
    }
    
    if (order.submittedBy && order.submittedBy.fullName) {
      return <span className="font-medium">{order.submittedBy.fullName}</span>;
    }
    
    return <span className="text-gray-500">לא ידוע</span>;
  };

  if (loading && orders.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex space-x-2 space-x-reverse">
          <button 
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            className={`flex items-center px-4 py-2 rounded-md transition-colors ${
              isFilterOpen 
                ? 'bg-blue-100 text-blue-700' 
                : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
            }`}
          >
            <FilterIcon size={16} className="ml-2" />
            {isFilterOpen ? 'הסתר סינון' : 'סנן תוצאות'}
          </button>
          
          <button 
            onClick={() => setIsExportModalOpen(true)}
            className="flex items-center px-4 py-2 rounded-md bg-green-50 text-green-600 hover:bg-green-100 transition-colors"
          >
            <FileText size={16} className="ml-2" />
            ייצא לאקסל
          </button>
        </div>
        <h1 className="text-2xl font-bold text-right">ניהול הזמנות ציוד משרדי</h1>
      </div>
      
      {/* Filters Section */}
      {isFilterOpen && (
        <div className="bg-white p-6 rounded-lg mb-6 shadow-md border border-gray-100">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700 mb-2 text-right">סינון לפי סניף</label>
              <div className="relative">
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <Package size={18} className="text-gray-400" />
                </div>
                <select
                  value={filters.branchId}
                  onChange={(e) => handleFilterChange('branchId', e.target.value)}
                  className="block w-full pr-10 py-2.5 text-right border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  style={{ 
                    backgroundPosition: "left 0.5rem center",
                    backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e\")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "1.5em 1.5em",
                    paddingLeft: "2.5rem"
                  }}
                >
                  <option value="">כל הסניפים</option>
                  {branches.map((branch) => (
                    <option key={branch._id} value={branch._id}>
                      {branch.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700 mb-2 text-right">סינון לפי מנהל אזור</label>
              <div className="relative">
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <Search size={18} className="text-gray-400" />
                </div>
                <select
                  value={filters.areaManager}
                  onChange={(e) => handleFilterChange('areaManager', e.target.value)}
                  className="block w-full pr-10 py-2.5 text-right border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  style={{ 
                    backgroundPosition: "left 0.5rem center",
                    backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e\")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "1.5em 1.5em",
                    paddingLeft: "2.5rem"
                  }}
                >
                  <option value="">כל מנהלי האזורים</option>
                  {areaManagers.map((manager) => (
                    <option key={manager._id} value={manager._id}>
                      {manager.fullName || manager.username}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            
            <div className="flex flex-col">
              <label className="block text-sm font-medium text-gray-700 mb-2 text-right">סינון לפי סטטוס</label>
              <div className="relative">
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <Tag size={18} className="text-gray-400" />
                </div>
                <select
                  value={filters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                  className="block w-full pr-10 py-2.5 text-right border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  style={{ 
                    backgroundPosition: "left 0.5rem center",
                    backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e\")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "1.5em 1.5em",
                    paddingLeft: "2.5rem"
                  }}
                >
                  <option value="">כל הסטטוסים</option>
                  <option value="pending">ממתין לאישור</option>
                  <option value="approved">אושר</option>
                  <option value="rejected">נדחה</option>
                  <option value="delivered">נמסר</option>
                </select>
              </div>
            </div>
          </div>
          
          <div className="flex justify-end mt-6">
            <button
              onClick={clearFilters}
              className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
            >
              נקה סינון
            </button>
          </div>
        </div>
      )}
      
      <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-100">
        {loading && (
          <div className="flex justify-center items-center p-6">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}
        
        {orders.length === 0 && !loading ? (
          <div className="p-12 text-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 mb-4">
              <Package className="h-6 w-6 text-gray-500" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">לא נמצאו הזמנות</h3>
            <p className="text-gray-500">
              לא נמצאו הזמנות שתואמות את הסינון שבחרת
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מספר הזמנה</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניף</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סה"כ פריטים</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סה"כ סכום</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מזמין</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">טלפון</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">פעולות</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {orders.map((order) => (
                  <tr key={order._id} className="hover:bg-gray-50 transition-colors">
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                      <div className="flex items-center justify-end">
                        <Calendar size={14} className="ml-1 text-gray-400" />
                        {formatDate(order.submittedAt)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-blue-600 font-bold">
                      <div className="flex items-center justify-end">
                        <Hash size={14} className="ml-1 text-blue-400" />
                        {order.orderNumber || order._id.substring(order._id.length - 8)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-900 font-medium">
                      {order.branchId && order.branchId.name ? order.branchId.name : 'לא ידוע'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                      {order.items.length}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-700">
                      ₪{order.totalAmount.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      <span className={`px-3 py-1 inline-flex text-xs leading-5 font-medium rounded-full ${getStatusColor(order.status)}`}>
                        {getStatusText(order.status)}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                      {order.requesterName || (order.submittedBy && order.submittedBy.fullName) || 'לא ידוע'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500">
                      {order.requesterPhone || 'לא צוין'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                      <div className="flex flex-col space-y-2 items-end">
                        <button 
                          onClick={() => viewOrderDetails(order)} 
                          className="p-2 text-gray-600 hover:text-gray-800 rounded-md hover:bg-gray-100 transition-colors flex items-center"
                          title="צפה בפרטים"
                        >
                          <Package size={16} className="ml-1" />
                          <span>פרטים</span>
                        </button>
                        
                        {generatePdfButton(order)}
                        
                        {order.status === 'pending' && (
                          <div className="flex space-x-2 space-x-reverse">
                            <button 
                              onClick={() => handleStatusChange(order._id, 'approved')} 
                              className="p-2 text-green-600 hover:text-green-800 rounded-md hover:bg-green-50 transition-colors flex items-center"
                              title="אשר הזמנה"
                            >
                              <CheckCircle size={16} className="ml-1" />
                              <span>אישור</span>
                            </button>
                            <button 
                              onClick={() => handleStatusChange(order._id, 'rejected')} 
                              className="p-2 text-red-600 hover:text-red-800 rounded-md hover:bg-red-50 transition-colors flex items-center"
                              title="דחה הזמנה"
                            >
                              <XCircle size={16} className="ml-1" />
                              <span>דחייה</span>
                            </button>
                          </div>
                        )}
                        
                        {order.status === 'approved' && (
                          <button 
                            onClick={() => handleStatusChange(order._id, 'delivered')} 
                            className="p-2 text-blue-600 hover:text-blue-800 rounded-md hover:bg-blue-50 transition-colors flex items-center"
                            title="סמן כנמסר"
                          >
                            <TruckIcon size={16} className="ml-1" />
                            <span>סמן כנמסר</span>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Order Details Modal */}
      {isModalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto shadow-xl">
            <div className="flex justify-between items-center mb-6 border-b pb-4">
              <button 
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full p-2 transition-colors"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
              <h2 className="text-xl font-bold flex items-center">
                <span className="ml-2">פרטי הזמנה</span>
                <span className="text-blue-600 font-mono bg-blue-50 px-2 py-1 rounded text-sm ml-2">
                  {selectedOrder.orderNumber || selectedOrder._id.substring(selectedOrder._id.length - 8)}
                </span>
              </h2>
            </div>
            
            <div className="bg-blue-50 p-5 rounded-lg mb-6">
              <div className="grid grid-cols-2 gap-6 text-right">
                <div>
                  <p className="text-sm text-gray-500 mb-1">סניף</p>
                  <div className="flex items-center">
                    <Package size={16} className="ml-2 text-blue-500" />
                    <p className="font-medium text-gray-800">{selectedOrder.branchId && selectedOrder.branchId.name ? selectedOrder.branchId.name : 'לא ידוע'}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-gray-500 mb-1">תאריך הזמנה</p>
                  <div className="flex items-center">
                    <Calendar size={16} className="ml-2 text-blue-500" />
                    <p className="font-medium text-gray-800">{formatDate(selectedOrder.submittedAt)}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm text-gray-500 mb-1">סטטוס</p>
                  <span className={`px-3 py-1 inline-flex text-xs leading-5 font-medium rounded-full ${getStatusColor(selectedOrder.status)}`}>
                    {getStatusText(selectedOrder.status)}
                  </span>
                </div>
                <div>
                  <p className="text-sm text-gray-500 mb-1">מזמין</p>
                  {getOrderSubmittedBy(selectedOrder)}
                </div>
              </div>
            </div>
            
            <div className="flex justify-between items-center mb-3">
              <button 
                onClick={() => {
                  console.log('Edit button clicked');
                  toggleEditMode();
                }}
                className={`p-2 ${isEditing ? 'text-red-600 hover:text-red-800' : 'text-blue-600 hover:text-blue-800'} rounded-md hover:bg-blue-50 transition-colors flex items-center`}
              >
                {isEditing ? (
                  <span className="flex items-center"><XCircle size={16} className="ml-1" /> ביטול עריכה</span>
                ) : (
                  <span className="flex items-center"><Edit size={16} className="ml-1" /> ערוך הזמנה</span>
                )}
              </button>
              
              <h3 className="text-lg font-medium text-right flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                </svg>
                פריטים שהוזמנו
              </h3>
            </div>
            
            <div className="overflow-x-auto mb-6 border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">כמות</th>
                    <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מחיר</th>
                    <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">שם פריט</th>
                    <th scope="col" className="px-4 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מק"ט</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {(isEditing ? editedItems : selectedOrder.items).map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-4 py-3 whitespace-nowrap text-right text-sm font-medium text-gray-700">
                        {isEditing ? (
                          <input
                            type="number"
                            min="0"
                            max="1000"
                            value={item.quantity}
                            onChange={(e) => handleItemQuantityChange(index, e.target.value)}
                            className="w-20 px-2 py-1 border rounded-md"
                          />
                        ) : (
                          item.quantity
                        )}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-right text-sm text-gray-700">₪{item.price.toFixed(2)}</td>
                      <td className="px-4 py-3 text-right text-sm text-gray-900">{item.name}</td>
                      <td className="px-4 py-3 whitespace-nowrap text-right text-sm text-gray-500">{item.itemId}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="bg-gray-50">
                  <tr>
                    <td colSpan="4" className="px-4 py-3 text-left text-sm font-medium">
                      סה"כ: <span className="font-bold text-blue-600">
                        ₪{isEditing ? calculateUpdatedTotal().toFixed(2) : selectedOrder.totalAmount.toFixed(2)}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            
            <div className="mb-6">
              <h3 className="text-lg font-medium mb-3 text-right flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                </svg>
                הערות
              </h3>
              {isEditing ? (
                <textarea
                  value={editedNotes}
                  onChange={(e) => setEditedNotes(e.target.value)}
                  className="w-full px-3 py-2 border rounded-md text-right"
                  rows="3"
                  placeholder="הערות להזמנה..."
                />
              ) : (
                <div className="bg-gray-50 p-4 rounded-lg text-right border">
                  {selectedOrder.notes || 'אין הערות'}
                </div>
              )}
            </div>
            
            <div className="mt-6 border-t pt-4 flex justify-between">
              <button 
                onClick={() => setIsModalOpen(false)} 
                className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
              >
                סגור
              </button>
              
              <div className="space-x-2 space-x-reverse">
                {isEditing && (
                  <button 
                    onClick={() => {
                      console.log('Save changes button clicked');
                      saveOrderChanges();
                    }}
                    disabled={isSaving}
                    className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center"
                  >
                    {isSaving ? (
                      <span className="flex items-center"><span className="animate-spin mr-1">⏳</span> שומר...</span>
                    ) : (
                      <span className="flex items-center"><Save size={16} className="ml-1" /> שמור שינויים</span>
                    )}
                  </button>
                )}
                
                {!isEditing && selectedOrder.status === 'pending' && (
                  <>
                    <button 
                      onClick={() => {
                        handleStatusChange(selectedOrder._id, 'approved');
                        setIsModalOpen(false);
                      }} 
                      className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                      אשר הזמנה
                    </button>
                    <button 
                      onClick={() => {
                        handleStatusChange(selectedOrder._id, 'rejected');
                        setIsModalOpen(false);
                      }} 
                      className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                    >
                      דחה הזמנה
                    </button>
                  </>
                )}
                
                {!isEditing && selectedOrder.status === 'approved' && (
                  <button 
                    onClick={() => {
                      handleStatusChange(selectedOrder._id, 'delivered');
                      setIsModalOpen(false);
                    }} 
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                  >
                    סמן כנמסר
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Export Modal */}
      {isExportModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[90vh] overflow-y-auto shadow-xl">
            <div className="flex justify-between items-center mb-6 border-b pb-4">
              <button 
                onClick={() => setIsExportModalOpen(false)}
                className="text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full p-2 transition-colors"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
              <h2 className="text-xl font-bold text-right">ייצוא הזמנות לאקסל</h2>
            </div>
            
            <div className="space-y-4">
              <p className="text-gray-600 text-right">בחר טווח תאריכים לייצוא הזמנות</p>
              
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 mb-2 text-right">תאריך התחלה</label>
                <div className="relative">
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <Calendar size={18} className="text-gray-400" />
                  </div>
                  <input
                    type="date"
                    value={exportDateRange.startDate}
                    onChange={(e) => setExportDateRange({...exportDateRange, startDate: e.target.value})}
                    className="block w-full pr-10 py-2.5 text-right border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700 mb-2 text-right">תאריך סיום</label>
                <div className="relative">
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <Calendar size={18} className="text-gray-400" />
                  </div>
                  <input
                    type="date"
                    value={exportDateRange.endDate}
                    onChange={(e) => setExportDateRange({...exportDateRange, endDate: e.target.value})}
                    className="block w-full pr-10 py-2.5 text-right border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              
              <div className="mt-6 border-t pt-4 flex justify-between">
                <button 
                  onClick={() => setIsExportModalOpen(false)} 
                  className="bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  ביטול
                </button>
                
                <button 
                  onClick={handleExport}
                  disabled={isExporting}
                  className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center"
                >
                  {isExporting ? (
                    <span className="flex items-center"><span className="animate-spin mr-1">⏳</span> מייצא...</span>
                  ) : (
                    <span className="flex items-center"><FileText size={16} className="ml-1" /> ייצא לאקסל</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminOfficeSuppliesOrders; 