import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Check, X, Clock, AlertTriangle, ThumbsUp } from 'lucide-react';
import { API_URL } from '../../config';

// Countdown timer component
const CountdownTimer = ({ expiresAt, onTimerEnd }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const expirationTime = new Date(expiresAt).getTime();
      const now = new Date().getTime();
      const difference = expirationTime - now;
      
      if (difference <= 0) {
        // Timer has ended
        onTimerEnd();
        return { hours: 0, minutes: 0, seconds: 0 };
      }
      
      return {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      };
    };

    // Initial calculation
    setTimeLeft(calculateTimeLeft());
    
    // Set up interval to update every second
    const timer = setInterval(() => {
      const timeLeft = calculateTimeLeft();
      setTimeLeft(timeLeft);
      
      // Check if timer has reached zero
      if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
        clearInterval(timer);
        onTimerEnd();
      }
    }, 1000);
    
    // Clean up interval on unmount
    return () => clearInterval(timer);
  }, [expiresAt, onTimerEnd]);

  return (
    <div className="bg-white/20 px-4 py-2 rounded-lg text-center backdrop-blur-sm">
      <div className="text-xs text-blue-100 mb-1">זמן נותר למילוי הטופס</div>
      <div className="font-bold">
        {timeLeft.hours.toString().padStart(2, '0')}:
        {timeLeft.minutes.toString().padStart(2, '0')}:
        {timeLeft.seconds.toString().padStart(2, '0')}
      </div>
    </div>
  );
};

export default function DistributionForm() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [responses, setResponses] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [nextForm, setNextForm] = useState(null);
  const [remainingForms, setRemainingForms] = useState(0);

  const fetchNextForm = useCallback(async () => {
    try {
      if (!form?.branch) return null;
      const response = await axios.get(`${API_URL}/api/distributions/branch-forms/${form.branch}`);
      const pendingForms = response.data.filter(f => f.status === 'pending' && f._id !== form._id);
      
      // Set the count of remaining forms
      setRemainingForms(pendingForms.length);
      
      return pendingForms.length > 0 ? pendingForms[0] : null;
    } catch (error) {
      console.error('Error fetching next form:', error);
      return null;
    }
  }, [form]);

  useEffect(() => {
    if (form && form.status === 'pending') {
      fetchNextForm().then(setNextForm);
    }
  }, [form, fetchNextForm]);

  const fetchForm = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/api/distributions/access/${token}`);
      setForm(response.data);
      
      // Check if form is expired
      const expirationDate = new Date(response.data.expiresAt);
      if (expirationDate < new Date()) {
        setIsExpired(true);
        setError('הטופס פג תוקף');
        return;
      }

      // Check if form has already been submitted
      if (response.data.status === 'completed') {
        setSubmitted(true);
        setError('הטופס כבר הוגש');
        return;
      }
      
      // Initialize responses
      const initialResponses = {};
      response.data.items.forEach(item => {
        initialResponses[item._id] = {
          response: item.response || null,
          requestedAmount: item.requestedAmount || null,
          notes: item.notes || ''
        };
      });
      setResponses(initialResponses);
    } catch (error) {
      console.error('Error fetching form:', error);
      setError(error.response?.data?.message || 'הטופס לא נמצא');
    } finally {
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  // Handler for when timer ends
  const handleTimerEnd = () => {
    setIsExpired(true);
    setError('הטופס פג תוקף');
  };

  const handleResponseChange = (itemId, field, value) => {
    setResponses(prev => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [field]: value
      }
    }));
    
    // Clear validation errors when appropriate fields are modified
    if (field === 'response') {
      setValidationErrors(prev => ({
        ...prev,
        [itemId]: null
      }));
    } else if (field === 'requestedAmount' && 
              (responses[itemId]?.response === 'increased' || responses[itemId]?.response === 'reduced')) {
      setValidationErrors(prev => ({
        ...prev,
        [itemId]: null
      }));
    } else if (field === 'notes' && 
              (responses[itemId]?.response === 'rejected' || 
               responses[itemId]?.response === 'reduced' || 
               responses[itemId]?.response === 'increased')) {
      setValidationErrors(prev => ({
        ...prev,
        [itemId]: null
      }));
    }
  };

  const validateForm = () => {
    const errors = {};
    let hasErrors = false;
    
    form.items.forEach(item => {
      // Check if a response is selected
      if (!responses[item._id]?.response) {
        errors[item._id] = { type: 'missing_response', message: 'נא לבחור אחת מהאפשרויות' };
        hasErrors = true;
      } 
      // Check if amount is provided when required
      else if ((responses[item._id].response === 'increased' || responses[item._id].response === 'reduced') && 
              (!responses[item._id].requestedAmount && responses[item._id].requestedAmount !== 0)) {
        errors[item._id] = { type: 'missing_amount', message: 'נא להזין את הכמות המבוקשת' };
        hasErrors = true;
      }
      // Check if notes are provided when rejecting, reducing or increasing item
      else if ((responses[item._id].response === 'rejected' || 
                responses[item._id].response === 'reduced' || 
                responses[item._id].response === 'increased') && 
               (!responses[item._id].notes || responses[item._id].notes.trim() === '')) {
        let actionType;
        if (responses[item._id].response === 'rejected') actionType = 'ביטול';
        else if (responses[item._id].response === 'reduced') actionType = 'הפחתה';
        else actionType = 'הגדלה';
        
        errors[item._id] = { 
          type: 'missing_notes', 
          message: `נא להזין הסבר ל${actionType} בשדה ההערות` 
        };
        hasErrors = true;
      }
    });
    
    setValidationErrors(errors);
    return !hasErrors;
  };

  const handleSubmit = async () => {
    // Check if form is expired
    if (isExpired) {
      setError('הטופס פג תוקף');
      return;
    }
    
    // Validate responses - must select an option for each item
    if (!validateForm()) {
      // Count different types of errors
      const missingResponseCount = Object.values(validationErrors).filter(err => err?.type === 'missing_response').length;
      const missingAmountCount = Object.values(validationErrors).filter(err => err?.type === 'missing_amount').length;
      const missingNotesCount = Object.values(validationErrors).filter(err => err?.type === 'missing_notes').length;
      
      let errorMsg = '';
      if (missingResponseCount > 0 && missingAmountCount > 0 && missingNotesCount > 0) {
        errorMsg = `יש לבחור אפשרות לכל פריט, להזין כמות מבוקשת עבור פריטים שסומנו להגדלה או הפחתה, ולהזין הסבר עבור כל שינוי`;
      } else if (missingResponseCount > 0 && missingAmountCount > 0) {
        errorMsg = `יש לבחור אפשרות לכל פריט ולהזין כמות מבוקשת עבור פריטים שסומנו להגדלה או הפחתה`;
      } else if (missingResponseCount > 0 && missingNotesCount > 0) {
        errorMsg = `יש לבחור אפשרות לכל פריט ולהזין הסבר עבור כל שינוי`;
      } else if (missingAmountCount > 0 && missingNotesCount > 0) {
        errorMsg = `יש להזין כמות מבוקשת עבור פריטים שסומנו להגדלה או הפחתה ולהזין הסבר עבור כל שינוי`;
      } else if (missingResponseCount > 0) {
        errorMsg = `יש לבחור אפשרות לכל פריט`;
      } else if (missingAmountCount > 0) {
        errorMsg = `יש להזין כמות מבוקשת עבור פריטים שסומנו להגדלה או הפחתה`;
      } else if (missingNotesCount > 0) {
        errorMsg = `יש להזין הסבר עבור כל שינוי`;
      }

      toast.error(errorMsg);
      return;
    }

    setSubmitting(true);
    try {
      // Format responses for API
      const formattedResponses = Object.entries(responses).map(([itemId, response]) => ({
        itemId,
        ...response
      }));

      await axios.post(
        `${API_URL}/api/distributions/${form._id}/submit`,
        { 
          responses: formattedResponses,
          accessToken: token
        }
      );

      // Set submitted state first
      setSubmitted(true);
      toast.success('הטופס הוגש בהצלחה');

      // If there's a next form, navigate immediately
      if (nextForm?.accessToken) {
        // Use replace instead of navigate to prevent back button issues
        window.location.href = `/distribution-form/${nextForm.accessToken}`;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || 'שגיאה בהגשת הטופס');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
    </div>
  );

  if (error || isExpired) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-center p-8">
        <div className="text-3xl mb-4">⚠️</div>
        <h2 className="text-2xl font-bold mb-2 text-red-600">
          {isExpired ? 'הטופס פג תוקף' : error}
        </h2>
        <p className="text-gray-600">
          {isExpired ? 'לא ניתן להגיש את הטופס לאחר שפג תוקפו' : 'אנא נסה שוב מאוחר יותר'}
        </p>
      </div>
    </div>
  );

  if (!form) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-center p-8">הטופס לא נמצא</div>
    </div>
  );

  if (submitted) return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-center p-8">
        <div className="text-3xl mb-4">✅</div>
        <h2 className="text-2xl font-bold mb-2">תודה!</h2>
        <p className="text-gray-600">
          {error === 'הטופס כבר הוגש' ? 'הטופס כבר הוגש ולא ניתן להגיש אותו שוב' : 'הטופס הוגש בהצלחה'}
        </p>
        {nextForm && (
          <div className="mt-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto mb-2"></div>
            <p className="text-blue-600">
              מעביר לטופס הבא...
            </p>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50" dir="rtl">
      <div className="max-w-4xl mx-auto p-2 sm:p-6">
        {/* Header */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-4">
            <div className="flex flex-col sm:flex-row gap-4 items-center justify-between mb-4">
              <div>
                <h1 className="text-lg sm:text-2xl font-bold text-center sm:text-right">{form.title}</h1>
                <p className="text-sm text-blue-100 text-center sm:text-right">סניף: {form.branchName}</p>
                
                {/* Form Progress Indicator */}
                {remainingForms > 0 && (
                  <div className="mt-2 bg-blue-700/50 rounded-lg px-3 py-1 mx-auto sm:mx-0 text-center w-max">
                    <p className="text-xs text-blue-100 flex items-center justify-center">
                      <span className="ml-1">נותרו עוד</span>
                      <span className="mx-1 bg-white text-blue-700 rounded-full w-5 h-5 flex items-center justify-center font-bold">
                        {remainingForms}
                      </span>
                      <span>טפסים למילוי</span>
                    </p>
                  </div>
                )}
              </div>
              
              {/* Countdown Timer */}
              <CountdownTimer 
                expiresAt={form.expiresAt} 
                onTimerEnd={handleTimerEnd}
              />
            </div>
          </div>
        </div>

        {/* User Guide */}
        <div className="bg-blue-50 border border-blue-200 rounded-xl p-4 mt-4 mb-2">
          <h2 className="font-medium text-blue-800 mb-2 text-lg">איך ממלאים את טופס החלוקות?</h2>
          <div className="space-y-2 text-sm text-blue-700">
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">1</span>
              <span>בחר אישור, הפחתה, הגדלה או ביטול עבור כל פריט</span>
            </p>
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">2</span>
              <span>במקרה של הפחתה או הגדלה, חובה להזין את הכמות המבוקשת</span>
            </p>
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">3</span>
              <span>הערות הן אופציונליות</span>
            </p>
            <p className="flex items-center gap-2">
              <span className="bg-blue-100 rounded-full w-5 h-5 flex items-center justify-center text-xs">4</span>
              <span>לחץ על 'שלח טופס' בסיום</span>
            </p>
          </div>
        </div>

        {/* Items */}
        <div className="bg-white rounded-xl shadow-lg mt-4">
          <div className="p-3 sm:p-6 space-y-6">
            {form.items.map((item, index) => (
              <div key={item._id} className={`border ${validationErrors[item._id] ? 'border-red-300 bg-red-50' : 'border-gray-200'} rounded-xl overflow-hidden`}>
                <div className={`p-4 ${validationErrors[item._id] ? 'bg-red-50' : 'bg-gray-50'}`}>
                  <div className="flex items-start gap-2">
                    <span className={`${validationErrors[item._id] ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'} rounded-full w-6 h-6 flex items-center justify-center text-sm shrink-0`}>
                      {index + 1}
                    </span>
                    <div className="flex-1">
                      <h3 className="text-base font-medium text-gray-800 leading-5">
                        {item.productName}
                      </h3>
                      {item.barcode && (
                        <p className="text-sm text-gray-500 mt-1">ברקוד: {item.barcode}</p>
                      )}
                      {item.supplier && (
                        <p className="text-sm text-gray-500 mt-1">ספק: {item.supplier}</p>
                      )}
                      <div className="mt-2 p-2 bg-blue-50 rounded border border-blue-100">
                        <span className="text-sm font-medium text-gray-700">גורם אירוז מומלץ: </span>
                        <span className="text-lg font-bold text-blue-600">{item.packageFactor}</span>
                      </div>
                      {validationErrors[item._id] && (
                        <p className="mt-2 text-sm text-red-600 flex items-center">
                          <AlertTriangle className="h-4 w-4 ml-1" />
                          {validationErrors[item._id].message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-4">
                  {/* Item response options */}
                  <div className="space-y-2">
                    <div className="flex flex-wrap gap-2">
                      <label className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                        <input
                          type="radio"
                          name={`response-${item._id}`}
                          value="approved"
                          checked={responses[item._id]?.response === 'approved'}
                          onChange={() => handleResponseChange(item._id, 'response', 'approved')}
                          className="form-radio h-4 w-4 text-green-600"
                        />
                        <span className="mr-2 text-gray-900 flex items-center">
                          <Check size={16} className="text-green-600 ml-1" />
                          מאשר
                        </span>
                      </label>
                      <label className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                        <input
                          type="radio"
                          name={`response-${item._id}`}
                          value="reduced"
                          checked={responses[item._id]?.response === 'reduced'}
                          onChange={() => handleResponseChange(item._id, 'response', 'reduced')}
                          className="form-radio h-4 w-4 text-amber-600"
                        />
                        <span className="mr-2 text-gray-900 flex items-center">
                          <span className="text-amber-600 ml-1">↓</span>
                          מבקש הפחתה
                        </span>
                      </label>
                      <label className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                        <input
                          type="radio"
                          name={`response-${item._id}`}
                          value="increased"
                          checked={responses[item._id]?.response === 'increased'}
                          onChange={() => handleResponseChange(item._id, 'response', 'increased')}
                          className="form-radio h-4 w-4 text-blue-600"
                        />
                        <span className="mr-2 text-gray-900 flex items-center">
                          <span className="text-blue-600 ml-1">↑</span>
                          מבקש הגדלה
                        </span>
                      </label>
                      <label className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer">
                        <input
                          type="radio"
                          name={`response-${item._id}`}
                          value="rejected"
                          checked={responses[item._id]?.response === 'rejected'}
                          onChange={() => handleResponseChange(item._id, 'response', 'rejected')}
                          className="form-radio h-4 w-4 text-red-600"
                        />
                        <span className="mr-2 text-gray-900 flex items-center">
                          <X size={16} className="text-red-600 ml-1" />
                          מבטל
                        </span>
                      </label>
                    </div>

                    {(responses[item._id]?.response === 'reduced' || responses[item._id]?.response === 'increased') && (
                      <div className="mt-3">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          כמות מבוקשת:
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="number"
                          min="0"
                          value={responses[item._id]?.requestedAmount || ''}
                          onChange={(e) => {
                            const newValue = e.target.value ? parseInt(e.target.value) : null;
                            
                            // Validate based on response type
                            if (responses[item._id]?.response === 'reduced') {
                              // For reduction: must be strictly smaller than package factor
                              if (newValue !== null && newValue >= item.packageFactor) {
                                toast.error('בהפחתה, הכמות המבוקשת חייבת להיות קטנה מהכמות המקורית');
                                return;
                              }
                            } else if (responses[item._id]?.response === 'increased') {
                              // For increase: must be strictly larger than package factor
                              if (newValue !== null && newValue <= item.packageFactor) {
                                toast.error('בהגדלה, הכמות המבוקשת חייבת להיות גדולה מהכמות המקורית');
                                return;
                              }
                            }
                            
                            handleResponseChange(item._id, 'requestedAmount', newValue);
                          }}
                          className={`p-2 border ${validationErrors[item._id]?.type === 'missing_amount' ? 'border-red-500 bg-red-50' : 'border-gray-300'} rounded-md w-full`}
                          placeholder="הזן כמות מבוקשת"
                        />
                        {validationErrors[item._id]?.type === 'missing_amount' && (
                          <p className="mt-1 text-sm text-red-600">
                            חובה להזין כמות מבוקשת
                          </p>
                        )}
                        {responses[item._id]?.response === 'reduced' && (
                          <p className="mt-1 text-xs text-amber-600">
                            בהפחתה, הכמות המבוקשת חייבת להיות קטנה מ-{item.packageFactor}
                          </p>
                        )}
                        {responses[item._id]?.response === 'increased' && (
                          <p className="mt-1 text-xs text-blue-600">
                            בהגדלה, הכמות המבוקשת חייבת להיות גדולה מ-{item.packageFactor}
                          </p>
                        )}
                      </div>
                    )}

                    <div className="mt-3">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        הערות {(responses[item._id]?.response === 'rejected' || 
                                 responses[item._id]?.response === 'reduced' || 
                                 responses[item._id]?.response === 'increased') ? 
                        <span>
                          <span className="text-red-500">*</span> 
                          (חובה להסביר סיבת {
                            responses[item._id]?.response === 'rejected' ? 'הביטול' : 
                            responses[item._id]?.response === 'reduced' ? 'ההפחתה' : 'ההגדלה'
                          })
                        </span> : 
                        '(אופציונלי)'}
                      </label>
                      <textarea
                        value={responses[item._id]?.notes || ''}
                        onChange={(e) => handleResponseChange(item._id, 'notes', e.target.value)}
                        className={`block w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                          validationErrors[item._id]?.type === 'missing_notes' ? 'border-red-500 bg-red-50' : 'border-gray-300'
                        }`}
                        rows="2"
                        placeholder={
                          responses[item._id]?.response === 'rejected' ? "חובה להסביר מדוע ביטלת את בקשת החלוקה לפריט זה" : 
                          responses[item._id]?.response === 'reduced' ? "חובה להסביר מדוע אתה מבקש הפחתה בכמות הפריט" : 
                          responses[item._id]?.response === 'increased' ? "חובה להסביר מדוע אתה מבקש הגדלה בכמות הפריט" :
                          "הוסף הערות כאן..."
                        }
                      />
                      {validationErrors[item._id]?.type === 'missing_notes' && (
                        <p className="mt-1 text-sm text-red-600">
                          חובה להזין הסבר {
                            responses[item._id]?.response === 'rejected' ? 'לביטול' : 
                            responses[item._id]?.response === 'reduced' ? 'להפחתה' : 'להגדלה'
                          } בשדה ההערות
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            onClick={handleSubmit}
            disabled={submitting || submitted}
            className={`w-full ${
              submitting || submitted
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-blue-600 hover:bg-blue-700'
            } text-white py-4 rounded-xl text-lg font-medium transition-colors`}
          >
            {submitting ? 'שולח...' : submitted ? 'הטופס נשלח' : 'שלח טופס'}
          </button>
        </div>
      </div>
    </div>
  );
} 